import React, { Component } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Header from "../../header/header";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import { getHeadersCustomer, getHeadersOrder } from "../../utility/utility";

class WebTmtDatabaseMaintenanceSearch extends Component {
  state = {
    id: "",
    aliasType: "",
    aliasIssuer: "",
    aliasId: "",
    billPhone: "",
    customerNum: "",
    customerNumber: "",
    accountNumber: "",
    customerId: "",
    mtcnDate: "",
    mtcn: "",
    bankId: "",
    mode: "",
    isLoading:false,
    isNewLoading:false,
    showWebTmtDatabaseMaintenanceConfirm: false,
    enableBtn: false,
    gridApi: null,
    submitStatusWebTmtDatabaseMaintenance: null,
    submitMsgWebTmtDatabaseMaintenance: "",
  };

 componentDidMount() {
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id = jwt_decode(accessToken.value).hqintlId;
      const operatorId = id.replace(/\D/g, "");
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const accessGrant =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_CCDATABASE);
      const accessGrantWrite =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_CCDATABASE);
      const webAdmin =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        (
          accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN) &&
          (
            accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
            accessRole.includes(process.env.REACT_APP_DB_VIEW_CCDATABASE)
          )
        );
      const accessMTCCDisplay =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_MTCCDISPLAY) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_ENCRYPTEDDATAVIEW);
      const readOnlyAccess = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ? true : false;
      this.setState(
        {
          accessToken: token,
          webAdmin: webAdmin,
          tokenWebTmt: accessToken.value,
          role: accessRole,
          accessGranted: accessGrant,
          accessGrantedWrite: accessGrantWrite,
          readOnlyAccess: readOnlyAccess,
          isCreditCardDecryprtRequired: accessMTCCDisplay ? 1 : 0,
          operatorId: operatorId,
        },
        () => {
          if (!this.state.accessGranted) {
            this.setState({
              submitStatusWebTmtDatabaseMaintenance: "Error",
              submitMsgWebTmtDatabaseMaintenance:
                "Unauthorized!Access Is Denied.",
            });
          }
        }
      );
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }


  onChangeWebTmtDatabaseMaintenance = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val, 
    });
  };

  // convertDateToLegacyFormatWTMS = (mtcnDate) => {
  //   let date = mtcnDate;
  //   let d1 = new Date(date);
  //   let newDate = [
  //     ("0" + (d1.getMonth() + 1)).slice(-2),
  //     ("0" + d1.getDate()).slice(-2),
  //     ("0" + d1.getFullYear()).slice(-2),
  //   ].join("");
  //   return newDate;
  // };

  onChangeControlNumberWithSpecialChars = (e) => {
    this.setState({
      //[e.target.name]: e.target.value.replace(/[^0-9-.]/, ""),
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlNumber = (e) => {
    let regex = /^\d*(\.\d{0,2})?$/;
    if (regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onValidateWebTmtDatabaseMaintenance = () => {
    if (this.state.mtcn !=='' &&
      this.state.mtcn.length !==10
    ) {
      this.setState({
        submitStatusWebTmtDatabaseMaintenance: "Error",
        submitMsgWebTmtDatabaseMaintenance:
          "MTCN Should Be Of 10 Digit",
      });
      return false;
    }
    if (
      this.state.mtcn !== "" &&
      (this.state.mtcnDate === "" || this.state.mtcnDate === 0)
    ) {
      this.setState({
        submitStatusWebTmtDatabaseMaintenance: "Error",
        submitMsgWebTmtDatabaseMaintenance:
          "Please Enter Value In MTCN Date Field",
      });
      return false;
    }    
    if (
      this.state.mtcnDate !== "" && this.state.bankId==="" &&
      (this.state.mtcn === "" || this.state.mtcn === 0)
    ) {
      this.setState({
        submitStatusWebTmtDatabaseMaintenance: "Error",
        submitMsgWebTmtDatabaseMaintenance:
          "Please Enter Value In One Of The Fields",
      });
      return false;
    }
    if(this.state.customerId !== '')
		{
			if(!this.isCharInBag(this.state.customerId,"1234567890.: -\\/"))
			{
				this.setState({
          submitStatusWebTmtDatabaseMaintenance: "Error",
          submitMsgWebTmtDatabaseMaintenance:
            "Please Enter Valid Account Number",
        });
				return false;
			}
			if((this.state.customerId.indexOf("-") !== -1 ||this.state.customerId.indexOf(":") !== -1) && this.state.customerId.length < 26){
				this.setState({
          submitStatusWebTmtDatabaseMaintenance: "Error",
          submitMsgWebTmtDatabaseMaintenance:
            "Please Enter Valid Account Number",
        });
				return false;
			}
		}

    if (
      this.state.aliasType === "" &&
      this.state.aliasIssuer === "" &&
      this.state.aliasId === "" &&
      this.state.customerId === "" &&
      this.state.billPhone === "" &&
      this.state.customerNum === "" &&
      this.state.bankId === "" &&
      this.state.mtcn === "" &&
      this.state.mtcnDate === ""
    ) {
      this.setState({
        submitStatusWebTmtDatabaseMaintenance: "Error",
        submitMsgWebTmtDatabaseMaintenance:
          "Please Enter Value In One Of The Fields",
      });
      return false;
    }    
    if (
      (this.state.aliasType !== "" ||
        this.state.aliasIssuer !== "" ||
        this.state.aliasId !== "") &&
      (this.state.aliasType === "" ||
        this.state.aliasIssuer === "" ||
        this.state.aliasId === "")
    ) {
      this.setState({
        submitStatusWebTmtDatabaseMaintenance: "Error",
        submitMsgWebTmtDatabaseMaintenance: "Must Enter Alias(type & isu & id)",
      });
      return false;
    }
    return true;
  };

 isCharInBag = (obj, bag) => {
    for(let i=0;i<obj.length;i++)
    {
        var c = obj.charAt(i);
        if(bag.indexOf(c) === -1)
            return false;
    }
    return true;
}

  getCustomerAccountInformationWTDM = () => {
    this.setState({
      isLoading:true,
      isNewLoading:false
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customeraccountinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            aliasType: this.state.aliasType ? this.state.aliasType :"",
            aliasIssuer: this.state.aliasIssuer ? this.state.aliasIssuer :"",
            aliasId: this.state.aliasId ? this.state.aliasId : "",
            customerId: this.state.customerId ? this.state.customerId : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId:this.state.operatorId,
          },
        }
      )
      .then((response) => {
        this.setState({
          isLoading:false,
          isNewLoading:false
        })
        if (response.status===200 &&
          response.data.records!==null &&
          response.data.records.length > 0
        ) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
          });
          if (this.state.singleData.responseType === 0) {
            this.props.history.push({
              pathname: "/web-tmt-customer-account-information",
              state: this.state,
            });
          }
          if (this.state.singleData.responseType === 1) {
            this.props.history.push({
              pathname: "/web-tmt-customer-information",
              state: this.state,
            });
          }
        } else {
          this.setState({
            submitStatusWebTmtDatabaseMaintenance: "Error",
            submitMsgWebTmtDatabaseMaintenance: response.data.errMsg,
          });
          this.handleClearWTDM();
          return false;          
        }
      });
      
  };

  getCustomerInformation = () => {
    this.setState({
      isLoading:true,
      isNewLoading:false
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/customerinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            accountNumber: this.state.customerId ? this.state.customerId :"",
            customerNumber: this.state.customerNum ? this.state.customerNum : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId:this.state.operatorId,
          },
        }
      )
      .then((response) => {
        this.setState({
          isLoading:false,
          isNewLoading:false
        })
        if (response.status===200 &&
          response.data.records !== null &&
          response.data.records.length > 0
        ) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
            accountNumber: this.state.customerId,
            customerAccountId: this.state.customerNum ? this.state.customerNum : "",
          });
          if (this.state.singleData.responseType === 1) {
            this.props.history.push({
              pathname: "/web-tmt-customer-information",
              state: this.state,
            });
          }
        } else {
          this.setState({
            submitStatusWebTmtDatabaseMaintenance: "Error",
            submitMsgWebTmtDatabaseMaintenance: response.data.errMsg,
          });
          this.handleClearWTDM();
          return false;
        }
      });
  };

  getBankInformation = () => {
     this.setState({
       isLoading:true,
     })
   
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/webtmtdbmaintenance/bankinfo`,
        {
          headers: {
            ...getHeadersOrder().headers,
            bankId: this.state.bankId ? this.state.bankId :"",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
          },
        }
      )
      .then((response) => {
          this.setState({
            isLoading:false
          })
		if (response.data !== "" && response.data.bankNumber===null && this.state.mode === "") {
          this.setState({
            submitStatusWebTmtDatabaseMaintenance: "Error",
            submitMsgWebTmtDatabaseMaintenance: "* Bank Record Not Found *",
          });
          this.handleClearWTDM();
          return false;
        } else if (response.data !== "" && this.state.mode === "") {
          this.setState({
            singleData: response.data,
            bankId: this.state.bankId,
            bankNumber:this.state.bankId,
            mode:""
          });
          this.props.history.push({
            pathname: "/web-tmt-bank-information",
            state: this.state,
          });
        }        
      });
  };

  getNewBankInformation = () => {
    this.setState({
      isNewLoading:true,
    })
  
   axios
     .get(
      `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/webtmtdbmaintenance/bankinfo`,
       {
         headers: {
           ...getHeadersOrder().headers,
           bankId: this.state.bankId ? this.state.bankId : "",
           Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
         },
       }
     )
     .then((response) => {
         this.setState({
           isNewLoading:false
         })
       if (response.data !== "" && response.data.bankNumber!==null && this.state.mode === "add") {
         this.setState({
           submitStatusWebTmtDatabaseMaintenance: "Error",
           submitMsgWebTmtDatabaseMaintenance:
             "* Record Exists Check The Correct Bank-Id *",
         });
         return false;
       }else if (response.data !== "" && response.data.bankNumber===null && this.state.mode === "add") {
         this.setState({
           singleData: {              
           bankId: "",
           add: false,
           bankNumber: "",
           bankCountry: "",
           bankCurrency: "",
           bankName1: "",
           bankPhone1: "",
           bankName2: "",
           bankPhone2: "",
           bankPhone3: "",
           bankPhone4: "",
           bankStatus: "",
           statusDate: "",
           createDate: "",
           userID: "",
           upadateDate: "",
           cardProd: "",
           cardInd: "",
           dailyCount: 0,
           dailyRefCount: 0,
           dailyDecCount: 0,
           cardType: "",
           weeklyCount: 0,
           weeklyRefCount: 0,
           weeklyDecCount: 0,
           transDate: "",
           calcFactor: "",
           MTD: 0,
           lastYrCount: 0,
           lastMoCount: 0,
           YTD: 0,
           },            
           //singleData: { 
             bankNumber: this.state.bankId, 
           //},
           bankId: this.state.bankId,
           mode:"add"
         });       
         
         this.props.history.push({
           pathname: "/web-tmt-bank-information",
           state: this.state,
         });
       }
       
     });
 };

  getTransactionHistory = () => {
    this.setState({
      isLoading:true,
      isNewLoading:false
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/txnhistory`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            mtcn: this.state.mtcn ? this.state.mtcn :"",
            // mtcnDate: this.convertDateToLegacyFormatWTMS(this.state.mtcnDate),
            mtcnDate: this.state.mtcnDate,
            customerId: this.state.customerId ? this.state.customerId : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId: this.state.operatorId
          },
        }
      )
      .then((response) => {        
        this.setState({

          isLoading:false,
          isNewLoading:false
        })
        if (response.status===200 && response.data.records!==null && response.data.records.length > 0) {
          this.setState({
            records: response.data.records,
            singleData: response.data.records[0],
          });
          this.props.history.push({
            pathname: "/web-tmt-transaction-history",
            state: this.state,
          });
          let tempEnableNext = this.state.records.length > 1 ? true : false;
          this.setState({
            enableNext: tempEnableNext,
          });
        } else {
          this.setState({
            submitStatusWebTmtDatabaseMaintenance: "Error",
            submitMsgWebTmtDatabaseMaintenance: response.data.errMsg,
          });
          this.handleClearWTDM();
          return false;
        }
      });
  };

  getAccountInformation = () => {
    this.setState({
      isLoading:true,
      isNewLoading:false
    })
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_CUSTOMER_SERVICE_URL}/v1/cus/dbview/webtmtdbmaintenance/accountinfo`,
        {
          headers: {
            ...getHeadersCustomer().headers,
            BillPhone: this.state.billPhone ? this.state.billPhone : "",
            CustomerNumber: this.state.customerNum
              ? this.state.customerNum
              : "",
            Authorization:this.state.tokenWebTmt?this.state.tokenWebTmt:'',
            userId:this.state.operatorId,
          },
        }
      )
      .then((responseWTDMS) => {
        this.setState({
          isLoading:false,
          isNewLoading:false
        })
        if (responseWTDMS.status===200 &&
          responseWTDMS.data.records !== null &&
          responseWTDMS.data.records.length > 0
        ) {
          this.setState({
            records: responseWTDMS.data.records,
            singleData: responseWTDMS.data.records[0],
          });
          let tempEnableNext = this.state.records.length > 1 ? true : false;
          this.setState({
            enableNext: tempEnableNext,
          });
          this.props.history.push({
            pathname: "/web-tmt-account-information",
            state: this.state,
          });
        } else {
          this.setState({
            submitStatusWebTmtDatabaseMaintenance: "Error",
            submitMsgWebTmtDatabaseMaintenance: responseWTDMS.data.errMsg,
          });
          this.handleClearWTDM();
          return false;
        }
      });
       
  };

  redirectToScreens = () => {    
    if (
      this.state.aliasType !== "" &&
      this.state.aliasIssuer !== "" &&
      this.state.aliasId !== ""
    ) {
      this.getCustomerAccountInformationWTDM();
    } else if (
      (this.state.customerId !== "" || this.state.customerNum !== "") &&
      this.state.billPhone === "" &&
      this.state.bankId === "" &&
      this.state.mtcn === "" &&
      this.state.mtcnDate === "" &&
      this.state.aliasType === "" &&
      this.state.aliasIssuer === "" &&
      this.state.aliasId === ""
    ) {
      this.getCustomerInformation();
    } else if (
      this.state.billPhone !== "" &&
      this.state.aliasType === "" &&
      this.state.aliasIssuer === "" &&
      this.state.aliasId === "" &&
      this.state.bankId === "" &&
      this.state.mtcn === "" &&
      this.state.mtcnDate === ""
    ) {
      this.setState({
        billPhone: this.state.billPhone,
        customerNum: this.state.customerNum,
      });
      this.getAccountInformation();
    } else if (
      (this.state.bankId !== "" &&
      this.state.aliasType === "" &&
      this.state.aliasIssuer === "" &&
      this.state.aliasId === "" &&
      this.state.mtcn === "" &&
      this.state.mtcnDate === "") || (this.state.bankId !== "" &&
      this.state.mtcnDate !== "" &&
      this.state.mtcn==="" &&
      this.state.aliasType === "" &&
      this.state.aliasIssuer === "" &&
      this.state.aliasId === "")
    ) {
      this.setState({
        bankId: this.state.bankId,
      });
      this.getBankInformation();
    } else if (
      this.state.mtcn !== "" &&
      this.state.mtcnDate !== "" &&
      this.state.aliasType === "" &&
      this.state.aliasIssuer === "" &&
      this.state.aliasId === ""
    ) {
      this.setState({
        mtcn: this.state.mtcn,
        mtcnDate: this.state.mtcnDate,
        customerId: this.state.customerId,
      });
      this.getTransactionHistory();
    } 
    // else if (
    //   this.state.bankId !== "" &&
    //   this.state.mtcnDate !== "" &&
    //   this.state.mtcn==="" &&
    //   this.state.aliasType === "" &&
    //   this.state.aliasIssuer === "" &&
    //   this.state.aliasId === ""
    // ) {
    //   this.setState({
    //     bankId: this.state.bankId,
    //   });
    //   this.getBankInformation();
    // }
  };
  handleClearWTDM = () => {
    this.setState({
      aliasType:'',
      aliasIssuer:'',
      aliasId:'',
      customerId:'',
      customerNum:'',
      billPhone:'',
      bankId:'',
      mtcnDate:'',
      mtcn:'',
    })
  }
  onHandleSearchWebTmtDatabaseMaintenance = () => {
    if (!this.onValidateWebTmtDatabaseMaintenance()) {
      this.handleClearWTDM();
      return;
    }
    this.setState({
      mode: "",
    });
    this.redirectToScreens();
  };

  onHandleNewWebTmtDatabaseMaintenance = () => {
    this.setState({
      mode: "add"
    });
    if (this.state.bankId !== "") {
      this.getNewBankInformation();
    }
  };
  render() {
    return this.props.authState.isAuthenticated ? (
      <>
        <Header
          history={this.props.history}
          heading="WEB/TMT DATABASE MAINTENANCE"
        />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body ">
              <h4 className="card-title main-heading ">
                WEB/TMT DATABASE MAINTENANCE
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  WEB/TMT SEARCH
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="aliasType" className="font-weight-bold">
                          Alias Type
                        </label>
                        <input
                          type="text"
                          maxLength="4"
                          value={this.state.aliasType}
                          className="form-control form-control-sm"
                          name="aliasType"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-aliasType"
                          placeholder="Alias Type"
                        />
                        <span className='font-size-10'>PCP, SSN, GAID, EMAI, USER, SUID, ETC</span>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="aliasIssuer"
                          className="font-weight-bold"
                        >
                          Alias Issuer
                        </label>
                        <input
                          type="text"
                          maxLength="25"
                          value={this.state.aliasIssuer}
                          className="form-control form-control-sm"
                          name="aliasIssuer"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-aliasIssuer"
                          placeholder="Alias Issuer"
                        />
                        <span className='font-size-10'>WU, USA, CAN, RTRA, ETC</span>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="aliasId" className="font-weight-bold">
                          Alias Id/ PCP Num
                        </label>
                        <input
                          type="text"
                          maxLength="70"
                          value={this.state.aliasId}
                          className="form-control form-control-sm"
                          name="aliasId"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-aliasId"
                          placeholder="Alias Id/ PCP Num"
                          autoComplete='off'
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerId"
                          className="font-weight-bold"
                        >
                          Account Number
                        </label>
                        <input
                          type="text"
                          maxLength="40"
                          value={this.state.customerId}
                          className="form-control form-control-sm"
                          name="customerId"
                          onChange={(e) => {
                            this.onChangeControlNumberWithSpecialChars(e);
                          }}
                          data-test="text-customerId"
                          placeholder="Account Number"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="billPhone" className="font-weight-bold">
                          Bill Phone
                        </label>
                        <input
                          type="text"
                          maxLength="15"
                          value={this.state.billPhone}
                          className="form-control form-control-sm"
                          name="billPhone"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-billPhone"
                          placeholder="Bill Phone"
                        />
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="customerNum"
                          className="font-weight-bold"
                        >
                          Customer Number
                        </label>
                        <input
                          type="text"
                          maxLength="16"
                          value={this.state.customerNum}
                          className="form-control form-control-sm"
                          name="customerNum"
                          onChange={(e) => {
                            this.onChangeControlNumber(e);
                          }}
                          data-test="text-customerNum"
                          placeholder="Customer Number"
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="mtcnDate" className="font-weight-bold">
                          MTCN Date
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          value={this.state.mtcnDate}
                          name="mtcnDate"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-mtcnDate"
                          placeholder="MTCN Date"
                        ></input>
                      </div>
                      <div className="col-lg">
                        <label htmlFor="mtcn" className="font-weight-bold">
                          MTCN
                        </label>
                        <input
                          type="text"
                          maxLength="10"
                          value={this.state.mtcn}
                          className="form-control form-control-sm"
                          name="mtcn"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-mtcn"
                          placeholder="MTCN"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="bankId" className="font-weight-bold">
                          Bank Id
                        </label>
                        <input
                          type="text"
                          maxLength="10"
                          value={this.state.bankId}
                          className="form-control form-control-sm"
                          name="bankId"
                          onChange={(e) => {
                            this.onChangeWebTmtDatabaseMaintenance(e);
                          }}
                          data-test="text-bankId"
                          placeholder="Bank Id"
                        />
                      </div>
                      <div className="col-lg"></div>
                      <div className="col-lg"></div>
                      <div className="col-lg"></div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3" />
                      <div className="col-lg-6 text-center mar-bottom5">
                        {this.state.isNewLoading ? (
                          /*<button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleNewWebTmtDatabaseMaintenance}
                            data-test="button-new"
                          >
                            NEW
                          </button>*/
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={this.onHandleNewWebTmtDatabaseMaintenance}
                            data-test="button-new"
                            disabled={
                              !this.state.bankId ||
                              !this.state.accessGrantedWrite 
                            }
                          >
                            NEW
                          </button>
                        )}

                        {this.state.isLoading ? (
                          <button
                            className="btn btn-warning mar-right10"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            &nbsp;&nbsp;Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning mar-right10"
                            onClick={
                              this.onHandleSearchWebTmtDatabaseMaintenance
                            }
                            data-test="button-update"
                            disabled={!this.state.accessGranted}
                          >
                            SEARCH
                          </button>
                        )}
                      </div>                      
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4"/>
                        <div className="col-lg-4">
                          {this.state.submitStatusWuDirectedPayerDetails ===
                            "OK" && (
                            <div className="alert alert-success alert-padding text-center notification">
                              {this.state.submitMsgWebTmtDatabaseMaintenance}
                            </div>
                          )}
                          {this.state.submitStatusWebTmtDatabaseMaintenance ===
                            "Error" && (
                            <div className="alert alert-danger alert-padding text-center notification">
                              {this.state.submitMsgWebTmtDatabaseMaintenance}
                            </div>
                          )}
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default withOktaAuth(WebTmtDatabaseMaintenanceSearch);
