import React, { Component } from "react";
import Header from "../../header/header";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import { Button } from "react-bootstrap";

class DirectedMaintenanceDetails extends Component {
  state={
    submitStatusDirectedMaintenanceDetails: null,
    submitMsgWuDirectedMaintenanceDetails: "",
  }

  componentDidMount(){
    const accessToken = this.props.authState.accessToken;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).hqintlId;
      const operatorIdDMD = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const accessGrantDMD =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS
        )||accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_DIRECTED) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN)
        ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_DIRECTEDREAD);       
      const accessGrantWriteDMD =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS 
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_DIRECTED) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_WEBADMIN);
      this.setState({
        accessToken: token,
        token:accessToken.value,
        role: accessRole,
        accessGrantedDMD: accessGrantDMD,
        accessGrantedWriteDMD: accessGrantWriteDMD,
        operatorIdDMD: operatorIdDMD
      }, () => {
        if(!this.state.accessGrantedDMD) { 
          this.setState({
            submitStatusDirectedMaintenanceDetails: 'Error',
            submitMsgDirectedMaintenanceDetails: "Unauthorized!Access is denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
    }
   
    onHandleNetworkLinkMaintenance = () =>{
      this.props.history.push({
        pathname:"/directed-maintenance-network-link-details",state:this.state
      });
    }

    onHandleWuDirectedPayerMaintenance =() =>{
      this.props.history.push({
        pathname:"/wu-directed-payer-details",state:this.state
      })
    }

  render() {
    //console.log(this.state)
    return (
      <div data-test="directed-management">
        <Header
          changeComponent={this.changeComponent}
          history={this.props.history}
          heading="DIRECTED MANAGEMENT"
        />
        <div className="container-fluid">
          <h4 className="card-title main-heading ">
            DIRECTED MAINTENANCE DETAILS
          </h4>
          <div>
            <div className="row">
              <div className="col-lg-4" />
              <div className="col-lg-4">
                {this.state.submitStatusDirectedMaintenanceDetails === "OK" && (
                  <div className="alert alert-success alert-padding notification">
                    {this.state.submitMsgDirectedMaintenanceDetails}
                  </div>
                )}
                {this.state.submitStatusDirectedMaintenanceDetails ===
                  "Error" && (
                  <div className="alert alert-danger alert-padding notification">
                    {this.state.submitMsgDirectedMaintenanceDetails}
                  </div>
                )}
              </div>
              <div className="col-lg-4" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4" />
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body padding-bottom5 maintenance-menu-box">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        {!this.state.accessGrantedDMD ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                            data-test="button-networklink"
                          >
                            NETWORK LINK MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleNetworkLinkMaintenance}
                            data-test="button-networklink1"
                          >
                            NETWORK LINK MAINTENANCE
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        {!this.state.accessGrantedDMD ? (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block"
                            disabled
                          >
                            WU DIRECTED PAYER MAINTENANCE
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            className="btn btn-warning btn-block btn-link"
                            onClick={this.onHandleWuDirectedPayerMaintenance}
                            data-test="button-wupayer"
                          >
                            WU DIRECTED PAYER MAINTENANCE
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4" />
          </div>
        </div>
      </div>
    );
  }
}

export default withOktaAuth(DirectedMaintenanceDetails);