import React, { Component } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import Header from "../../header/header";
import { getHeadersOrder } from "../../utility/utility";

class QueueAgeWorkflowMonitors extends Component {
  state = {
    mtcn: "",
    date: "",
    checkboxOptions: "",
    submitStatusQueueAgeMonitor: null,
    submitMsgQueueAgeMonitor: "",
    selectedItems: [],
    rows: [],
    records: [],
    arr: [],
    noCheckBoxes: ''
  };
  componentDidMount() {
    const accessToken = this.props.authState.accessToken;
    const tokenQAWM = accessToken.value;
    let operatorId;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const id = jwt_decode(accessToken.value).hqintlId;
      const operatorIdQAWM = id.replace(/\D/g, "");
      operatorId = operatorIdQAWM;
      const accessGrantQAWM = 
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS
        ) ||
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCDELIVERYMONITOR);

      const accessGrantWriteQAWM =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCMANAGER) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCCLERK) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_QQCDELIVERYMONITOR);
        if(accessGrantQAWM) {
          axios
            .get(
              `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queueagegroups`,
              {
                headers: {
                  ...getHeadersOrder().headers,
                  Authorization: tokenQAWM,
                  userId: operatorId,
                },
              }
            )
            .then((response) => {
            this.setState({
              rows: response.data.records,
            });
          });
        }    
      this.setState(
        {
          accessToken: token,
          tokenQAWM: tokenQAWM,
          operatorIdQAWM: operatorIdQAWM,
          role: accessRole,
          accessGrantQAWM: accessGrantQAWM,
          accessGrantWriteQAWM: accessGrantWriteQAWM,
        },
        () => {
          if (!this.state.accessGrantQAWM) {
            this.setState({
              submitStatusQueueAgeMonitor: "Error",
              submitMsgQueueAgeMonitor: "Unauthorized!Access Is Denied.",
            });
          }
        }
      );
      // valid token format
    } catch (error) {
      // invalid token format
    }
    
   }
  onChangeControlQueueAgeMonitor = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  covertToLegacyQAWM = (date) => {
    let d1 = new Date(date);
    let newStartDateQAWM = [
      ("0" + (d1.getMonth() + 1)).slice(-2),
      ("0" + d1.getDate()).slice(-2),
      d1.getFullYear(),
    ].join("/");
    return newStartDateQAWM;
  };

  onValidateQueueAgeMonitor = () => {
    let mtcn = this.state.mtcn.trim();
    if (
      this.state.mtcn === "" &&
      this.state.date === "" &&
      this.state.arr.length < 1
    ) {
      this.setState({
        submitStatusQueueAgeMonitor: "Error",
        submitMsgQueueAgeMonitor:
          "Que Groups, MTCN And Date Fields Are Empty Or Invalid. Please Make Some Selections",
      });
      return false;
    }
    if (mtcn.length > 10) {
      this.setState({
        submitStatusQueueAgeMonitor: "Error",
        submitMsgQueueAgeMonitor: "Please Enter A 10 Digit Valid MTCN",
      });
      return false;
    }
    if (mtcn.length > 0 && mtcn.length < 10) {
      this.setState({
        submitStatusQueueAgeMonitor: "Error",
        submitMsgQueueAgeMonitor: "Please Enter A 10 Digit Valid MTCN",
      });
      return false;
    }
    return true;
  };

  onChangeCheckBoxQueueAgeMonitor = (params, e) => {
    let { value } = e.target;
    let queueArray = this.state.arr;
    let arrayIndex = this.state.arr.indexOf(value);
    if (arrayIndex === -1) {
      queueArray.push(value);
      this.setState({ arr: queueArray });
    } else {
      let array = this.state.arr;
      array.splice(arrayIndex, 1);
      this.setState({ arr: array });
    }
  };

  onHandleSearchQueueAgeMonitor = () => {
    if (!this.onValidateQueueAgeMonitor()) {
      return;
    }

    if (this.state.mtcn || this.state.date) {
      let submittedObjQAWM = {};
      submittedObjQAWM.mtcn = this.state.mtcn;
      submittedObjQAWM.startdate =
        this.state.date === "" ? "" : this.covertToLegacyQAWM(this.state.date);
      submittedObjQAWM.queueNameList = this.state.arr;
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queuetransaction`,
          submittedObjQAWM,
          {
            headers: {
              ...getHeadersOrder().headers,
              Authorization: this.state.tokenQAWM,
              userId: this.state.operatorIdQAWM,
            },
          }
        )
        .then((response) => {
          this.props.history.push({
            pathname: "/queue-trans-screen",
            state: {
              data: this.state,
              flag: false,
              records: response.data.records,
            },
          });
        });
    } else if (this.state.arr.length > 0) {
      const submittedObjQAWM = { records: this.state.arr };
      axios
        .post(
          `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_SERVICE_URL}/v1/ord/dbview/queueagemonitor/queuelist`,
          submittedObjQAWM,
          {
            headers: {
              ...getHeadersOrder().headers,
              Authorization: this.state.tokenQAWM,
              userId: this.state.operatorIdQAWM,
            },
          }
        )
        .then((response) => {
          let recQAWM = [];
          if (response.data.records !== null) {
            recQAWM = response.data.records;
          }
          if (recQAWM.length > 0) {
            const check = this.state;
            this.props.history.push({
              pathname: "/queue-screen",
              state: { data: check },
            });
          } else {
            this.setState({
              submitStatusQueueAgeMonitor: "Error",
              submitMsgQueueAgeMonitor:
                "No Transactions Found In The Queues For This Que Group. Select Another Queue Group",
            });
          }
        });
    }
  };
  onHandleQueAgeWorkflowMonitor = () => {
    this.props.history.push({
      pathname: "/queue-age-workflow-monitor",
      state: this.state,
    });
  };
  onHandleQueAgeWorkflowMaintenance= () => {
    this.props.history.push({
      pathname: "/queue-age-workflow-maintenance",
      state: this.state,
    });
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="QUEUE AGE MONITOR" />
        <div className="container-fluid">
          <div className="card-body">
            <h4 className="card-title main-heading ">
              QUEUE AGE MONITOR
            </h4>
            <div className="card">
              <div className=" bg-dark text-white card-header-custom">
                QUEUE AGE GROUPS
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4">
                    <div className="form-check ">
                      {this.state.rows.map((el, i) => (
                        <div key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkboxOptions"
                            id={el.queGroupId}
                            value={el.queGroupId}
                            onChange={(e) => {
                              this.onChangeCheckBoxQueueAgeMonitor(el, e);
                            }}
                            data-test="select-checkbox-qawm"
                          />
                          <label className="form-check-label">
                            {el.queGroupDesc}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="card-body file-alert mar-bottom5">
                  <div className=" bg-dark text-white card-header-custom">
                    GLOBAL SELECTION CRITERIA
                  </div>
                  <div className="card ">
                    <div className="card-body">
                      <div className="form-group row">
                        <div className="col-lg-6">
                          <label htmlFor="mtcn" className="font-weight-bold">
                            MTCN
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="mtcn"
                            value={this.state.mtcn}
                            onChange={(e) => {
                              this.onChangeControlQueueAgeMonitor(e);
                            }}
                            data-test="text-mtcn"
                          />
                        </div>
                        <div className="col-lg-6">
                          <Form.Label className="font-weight-bold">
                            Start Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={this.state.date}
                            onChange={(e) => {
                              this.onChangeControlQueueAgeMonitor(e);
                            }}
                            data-test="text-startDate"
                          />
                        </div>
                      </div>
                      <Card.Text>(10 Digit. For ex: 3456129442)</Card.Text>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-5" />
                      <div className="col-lg-7">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={this.onHandleSearchQueueAgeMonitor}
                          data-test="button-search"
                          disabled={!this.state.accessGrantQAWM}
                        >
                          SEARCH
                        </button>
                      </div>
                      <div className="col-lg-0" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg">
                        {this.state.submitStatusQueueAgeMonitor === "OK" && (
                          <div className="alert alert-success alert-padding notification text-center">
                            {this.state.submitMsgQueueAgeMonitor}
                          </div>
                        )}
                        {this.state.submitStatusQueueAgeMonitor === "Error" && (
                          <div className="alert alert-danger alert-padding notification text-center">
                            {this.state.submitMsgQueueAgeMonitor}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="card-body file-alert"
          data-test="component-landing-page"
        >
          <div className=" bg-dark text-white card-header-custom">
            QUE AGE WORKFLOW GROUP
          </div>
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-lg-6">
                  <Button
                    type="button"
                    className="btn btn-warning btn-block"
                    onClick={this.onHandleQueAgeWorkflowMonitor}
                    data-test="button-workflowmonitor"
                    disabled={!this.state.accessGrantQAWM}
                  >
                    QUE AGE WORKFLOW MONITOR
                  </Button>
                </div>
                <div className="col-lg-6">
                  <Button
                    type="button"
                    className="btn btn-warning btn-block"
                    onClick={this.onHandleQueAgeWorkflowMaintenance}
                    data-test="button-workflowmonitor"
                    disabled={!this.state.accessGrantQAWM}
                  >
                    QUE AGE WORKFLOW MAINTENANCE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default withOktaAuth(QueueAgeWorkflowMonitors);
