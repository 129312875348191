import React, { Component } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AgGridReact as AgGridReactNL } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AiTwotoneEdit as AiTwotoneEditNL } from "react-icons/ai";
import { IconContext as IconContextNL } from "react-icons";
import Header from "../header/header";
import { getHeadersShared } from "../utility/utility";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

 
class NetworkList extends Component {
  state = {
    networkId: "",
    networkName: "",
    showNetworkListConfirm: false,
    columns: [
      {
        headerName: "Action",
        field: "value",
        cellRendererFramework: (params) => {
          return (
            <div className="cursor-pointer text-center" onClick={() => this.state.accessGrantWriteNL && this.handleEditNetworkList(params)}>
              <IconContextNL.Provider value={{ color: this.state.accessGrantWriteNL ?"#FDD017":'#cec9b4', size: "20px" }}>
                <AiTwotoneEditNL />
              </IconContextNL.Provider>
            </div>  
          );
        },
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: "80px",
      },
      { field: "networkId", headerName: "Network Id", width: "570px" },
      { field: "networkName", headerName: "Network Name", width: "570px" }
    ],
    rows: null,
    enableBtn: false,
    gridApi: null,
    paginationValNetwork: "25",
    defaultColumnDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    submitStatusNetworkList: null,
    submitMsgNetworkList: "",
    paginationOptionNetworkList: [25, 50, 100, 500],
    overlayLoadingTemplateNL:
      '<span class="ag-overlay-loading-center">Loading...</span>',
    overlayNoRowsTemplateNL: '<span style="">No Records Found</span>',
  };

  onChangeControlNetworkListNetworkId = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, '')
    });
  };

  onChangeControlNetworkListNetworkName = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9\s'/#]/, "")
    });
  };
  
  onChangeControlNetworkList = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  

  onGridReadyNetworkList = (params) => {
    if (params) {
      this.setState({ gridApi: params.api });
    }
    if(!this.state.accessGrantNL) {
      this.setState({
        rows:[],
        overlayNoRowsTemplateNL: '<span style="">Unauthorized! Access Is Denied.</span>',
      });
    } else {
      axios
      .get(`${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/wusystemmonitor/networklist`,
      {
        headers: {
          ...getHeadersShared().headers,
          Authorization: this.state.tokenNL,
          userId: this.state.operatorIdNL,
        },
      })       
      .then((response) => {
        //console.log(response);
        let recNetworkList = [];
        if (response.data.records !== null) {
          recNetworkList = response.data.records;
        }
        this.setState({
          rows: recNetworkList,
        });
      });
    }
    
  };

  componentDidMount(){    
    const accessToken = this.props.authState.accessToken;
    const tokenNL = accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const id=jwt_decode(accessToken.value).hqintlId;
      const operatorIdNL = id.replace(/\D/g,'');
      const accessGrantNL = 
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS) ;
      const accessGrantWriteNL =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        (accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONTRACE) &&
        accessRole.includes(process.env.REACT_APP_DB_VIEW_TMONACCESS));

      this.setState({
        accessToken: token,
        tokenNL:tokenNL,
        role: accessRole,
        operatorIdNL:operatorIdNL,
        accessGrantNL:accessGrantNL,
        accessGrantWriteNL: accessGrantWriteNL,
      }, () => {
        if(!this.state.accessGrantNL) { 
          this.setState({
            submitStatusNetworkList: 'Error',
            submitMsgNetworkList: "Unauthorized! Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }
  handleEditNetworkList = (params) => {
    let data = params.node.data;
    this.setState({
      networkId: data.networkId,
      networkName: data.networkName,
      tipRecNumber: data.tipRecNumber,
      enableBtn: true,
    });
  };

  onPageSizeChangedNetworkList = (e) => {
    this.setState({ paginationValNetwork: e.target.value });
    this.state.gridApi.paginationSetPageSize(Number(e.target.value));
  };
  onHandleBackNetworkList = () => {
    this.props.history.push("/");
  };

  onValidateNetworkList = () => {
    if (this.state.networkId === "") {
      this.setState({
        submitStatusNetworkList: "Error",
        submitMsgNetworkList: "Please Enter The Value For Network Id ",
      });
      return false;
    }
    if (this.state.networkName === "") {
      this.setState({
        submitStatusNetworkList: "Error",
        submitMsgNetworkList: "Please Enter The Value For Network Name ",
      });
      return false;
    }
    return true;
  };

  onHandleAddNetworkList = () => {
    if (!this.onValidateNetworkList()) {
      return;
    }
    let submittedObj = {};
    submittedObj.networkId = this.state.networkId;
    submittedObj.networkName = this.state.networkName;
    axios
      .post(`${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/wusystemmonitor/networklist`, submittedObj, 
      {
        headers: {
          ...getHeadersShared().headers,
          Authorization: this.state.tokenNL,
          userId: this.state.operatorIdNL,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseString==='Record Added Successfully') {
          this.setState({
            submitStatusNetworkList: "OK",
            submitMsgNetworkList: response.data.responseString,
            rows: [submittedObj, ...this.state.rows],
          });
        } else {
          this.setState({
            submitStatusNetworkList: "Error",
            submitMsgNetworkList: response.data.responseString,
          });
        }
        this.onHandleClearNetworkList();
      });
  };

  onHandleUpdateNetworkList = () => {
    if (!this.onValidateNetworkList()) {
      return;
    }
    let submittedObj = {};
    submittedObj.networkId = this.state.networkId;
    submittedObj.networkName = this.state.networkName;
    axios
      .put(`${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/wusystemmonitor/networklist`,
      submittedObj, 
      {
        headers: {
          ...getHeadersShared().headers,
          Authorization: this.state.tokenNL,
          userId: this.state.operatorIdNL,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseCode==="200") {
          const updatedRecordIndex = this.state.rows.findIndex(
            (row) => row.networkId === submittedObj.networkId
          );
          const updatedRows = [
            ...this.state.rows.slice(0, updatedRecordIndex),
            submittedObj,
            ...this.state.rows.slice(updatedRecordIndex + 1),
          ];
          this.setState({
            submitStatusNetworkList: "OK",
            submitMsgNetworkList: response.data.responseString,
            rows: updatedRows,
          });
        } else {
          this.setState({
            submitStatusNetworkList: "Error",
            submitMsgNetworkList: response.data.responseString,
          });
        }
        this.onHandleClearNetworkList();
      });
  };

  onHandleClearNetworkList = () => {
    this.setState({
      networkId: "",
      networkName: "",
      enableBtn: false,
    });
    setTimeout(
      () =>
        this.setState({
          submitStatusNetworkList: null,
        }),
      5000
    );
  };

  getNetworkListModal = () => {
    return (
      <div>
        <Modal
          show={this.state.showNetworkListConfirm}
          animation={true}
          size="md"
          className="shadow-lg border"
        >
          <Modal.Header className="bg-dark text-white text-center py-1">
            <Modal.Title className="text-center">
              <h5 className="modal-header-custom">Are You Sure, You Want To Delete Network Id {this.state.networkId} ?</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="py-1 d-flex justify-content-center">
            <div>
              <Button
                variant="warning"
                onClick={() => this.setState({ showNetworkListConfirm: false })}
                data-test="button-no"
              >
                No
              </Button>
            </div>
            <div>
              <Button
                variant="warning"
                className="mx-2 px-3"
                onClick={this.onHandleDeleteNetworkList}
                data-test="button-yes"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  onHandleDeleteNetworkList = () => {
    axios
      .delete(`${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/wusystemmonitor/networklist/${this.state.networkId}/${this.state.networkName}`, 
      {
        headers: {
          ...getHeadersShared().headers,
          Authorization: this.state.tokenNL,
          userId: this.state.operatorIdNL,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.responseCode==="200") {
          let newRows = this.state.rows.filter((row) => {
            return row.networkId !== this.state.networkId;
          });
          this.setState({
            submitStatusNetworkList: "OK",
            submitMsgNetworkList: response.data.responseString,
            rows: newRows,
          });
        } else {
          this.setState({
            submitStatusNetworkList: "Error",
            submitMsgNetworkList: response.data.responseString,
          });
        }
        this.onHandleClearNetworkList();
      });
      this.setState({ showNetworkListConfirm: false });
  };

  render() {
    return this.props.authState.isAuthenticated ?(
      <>
        <Header history={this.props.history} heading="SYSTEM MONITOR" />

        <div className="container-fluid" data-test="component-network-list">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title main-heading "> 
                Transaction Monitor File Maintenance
              </h4>
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  Network Monitor
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="form-group row">
                      <div className="col-lg">
                        <label htmlFor="networkId" className="font-weight-bold">Network Id</label>
                        <input
                          type="text"
                          value={this.state.networkId}
                          className="form-control form-control-sm"
                          name="networkId"
                          maxlength="5"
                          onChange={(e) => {
                            this.onChangeControlNetworkListNetworkId(e);
                          }}
                          data-test="text-networkId"
                          placeholder="Network Id"
                          disabled={this.state.enableBtn}
                        />
                      </div>
                      <div className="col-lg">
                        <label htmlFor="networkName" className="font-weight-bold">Network Name</label>
                        <input
                          type="text"
                          value={this.state.networkName}
                          className="form-control form-control-sm"
                          maxlength="40"
                          name="networkName"
                          onChange={(e) => {
                            this.onChangeControlNetworkListNetworkName(e);
                          }}
                          data-test="text-networkName"
                          placeholder="Network Name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6 text-center mar-bottom5">
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleBackNetworkList}
                          data-test="button-back"
                        >
                          TMON DETAIL
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          disabled={this.state.enableBtn || !this.state.accessGrantNL ||!this.state.accessGrantWriteNL }
                          onClick={this.onHandleAddNetworkList}
                          data-test="button-add"
                        >
                          ADD
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleUpdateNetworkList}
                          data-test="button-update"
                          disabled={!this.state.enableBtn}
                        >
                          UPDATE
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning mar-right10"
                          onClick={this.onHandleClearNetworkList}
                          disabled={!this.state.enableBtn}
                        >
                          CLEAR
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => {
                            this.setState({ showNetworkListConfirm: true });
                          }}
                          data-test="button-delete"
                          disabled={!this.state.enableBtn}
                        >
                          DELETE
                        </button>
                        <div className="row mar-top10">
                          <div className="col-lg-2"></div>
                          <div className="col-lg-8">
                          {this.state.submitStatusNetworkList === "OK" && (
                            <div className="alert alert-success alert-padding notification">
                              {this.state.submitMsgNetworkList}
                            </div>
                          )}
                          {this.state.submitStatusNetworkList === "Error" && (
                            <div className="alert alert-danger alert-padding notification">
                              {this.state.submitMsgNetworkList}
                            </div>
                          )}  
						              </div>
                          <div className="col-lg-2"></div>
                        </div>
                      </div>
                      <div className="col-lg-3"></div>
                    </div>
                  </form>
                </div>
              </div>
              {this.getNetworkListModal()}
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  Network List
                </div>
                <div className="card-body card-body-custom">
                  <div className="pagination-dropdown">
                    <label htmlFor="pagination-select-label" className="font-weight-bold" >
                      No of records per page:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        this.onChangeControlNetworkList(e);
                        this.onPageSizeChangedNetworkList(e);
                      }}
                      value={this.state.paginationValNetwork}
                      name="paginationDropdownNetwork"
                      data-test="select-dropdown"
                    >
                      {this.state.paginationOptionNetworkList.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className="ag-theme-balham"
                    style={{ height: 250, width: "100%" }}
                  >
                    <AgGridReactNL
                      rowData={this.state.rows}
                      columnDefs={this.state.columns}
                      defaultColDef={this.state.defaultColumnDef}
                      pagination={true}
                      paginationPageSize={25}
                      onGridReady={this.onGridReadyNetworkList}
                      data-test="component-grid"
                      enableCellTextSelection={true}
                      overlayLoadingTemplate={
                        this.state.overlayLoadingTemplateNL
                      }
                      overlayNoRowsTemplate={
                        this.state.overlayNoRowsTemplateNL
                      }
                    ></AgGridReactNL>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ):null;
  }
}

export default withOktaAuth(NetworkList);
