import React, { Component } from "react";
import axios from "axios";
import Header from "../../header/header";
import { getHeadersAgent } from "../../utility/utility";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

class CheckControlUtility extends Component {
  state = {
    checkControlUtilityValues: {},
    agentAccount: "",
    controlCheck: "1",
    checknumber: "",
    numberOfCheck: "",
    agt_bankcode: "",
    agt_aname: "",
    agt_encash: "",
    corectAgentFlag: false,
    secondFlag: false,
    firstUseFlag: false,
    addCheckFlag: false,
    adjInvFlag: false,
    rmvCheckFlag: false,
    continueFlag:false,
    correct: "Y",
    continueValue:"N",
    submitStatusAgentCheckControlUtility: null,
    submitMsgAgentCheckControlUtility: "",
    showAgentCheckControlUtilityConfirm: false,
  };

  componentDidMount(){    
    const accessToken = this.props.authState.accessToken;
    const tokenCCU = accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const id=jwt_decode(accessToken.value).hqintlId;
      const operatorIdCCU = id.replace(/\D/g,'');
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const accessGrantCCU =accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||accessRole.includes(process.env.REACT_APP_DB_VIEW_CKUTILITY);
      const accessGrantWriteCCU =accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||accessRole.includes(process.env.REACT_APP_DB_VIEW_CKUTILITY);
      this.setState({
        accessToken: token,
        tokenCCU: tokenCCU,
        role: accessRole,
        accessGrantCCU: accessGrantCCU,
        accessGrantWriteCCU: accessGrantWriteCCU,
        operatorIdCCU: operatorIdCCU
      }, () => {
        if(!this.state.accessGrantCCU) { 
          this.setState({
            submitStatusAgentCheckControlUtility: 'Error',
            submitMsgAgentCheckControlUtility: "Unauthorized! Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }


  onChangeControlAgentCheckControlUtility = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeControlAgentCheckControlUtilityAgent = (e) => {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onValidateAgentCheckControlUtility = () => {
    if (this.state.agentAccount === "") {
      this.setState({
        submitStatusAgentCheckControlUtility: "Error",
        submitMsgAgentCheckControlUtility: "Please Enter The Value For Agent Account",
      });
      return false;
    }
    return true;
  };

  onValidateSecondAgentCheckControlUtility = () => {
    if (
      this.state.firstUseFlag &&
      this.state.controlCheck === "1" &&
      this.state.checknumber === ""
    ) {
      this.setState({
        submitStatusAgentCheckControlUtility: "Error",
        submitMsgAgentCheckControlUtility:
          "Please Enter The Value For Beginning Check",
      });
      return false;
    }
    if (
      this.state.addCheckFlag &&
      this.state.controlCheck === "3" &&
      this.state.checknumber === ""
    ) {
      this.setState({
        submitStatusAgentCheckControlUtility: "Error",
        submitMsgAgentCheckControlUtility: "Please Enter The Value For Number Of Checks",
      });
      return false;
    }
    if (
      this.state.adjInvFlag &&
      this.state.controlCheck === "5" &&
      this.state.numberOfCheck === ""
    ) {
      this.setState({
        submitStatusAgentCheckControlUtility: "Error",
        submitMsgAgentCheckControlUtility:
          "Please Enter The Value For Number Of Checks On Hand",
      });
      return false;
    }
    return true;
  };

  onHandleSubmitAgentCheckControlUtility = () => {
    if (this.state.controlCheck === "8") {
      this.props.history.push("/");
    }
    if (!this.onValidateAgentCheckControlUtility()) {
      return;
    }
    if (this.state.agentAccount && this.state.controlCheck) {
      this.setState({
        corectAgentFlag: true,
        secondFlag: true,
      });
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/Checkutility/${this.state.agentAccount}/${this.state.controlCheck}`,
        {
          headers: {
            ...getHeadersAgent().headers,
            Authorization: this.state.tokenCCU,
            userId: this.state.operatorIdCCU,
          },
        }
      )
      .then((response) => {
        if (response.data.flag === 1) {
          this.setState({
            checkControlUtilityValues: response.data,
            submitStatusAgentCheckControlUtility: "",
            submitMsgAgentCheckControlUtility: "",
          });
        } else {
          this.setState({
            submitStatusAgentCheckControlUtility: "Error",
            submitMsgAgentCheckControlUtility: response.data.message,
          });
          this.onHandleClearAgentCheckControlUtility();
        }
        // if (
        //   response.data.acctclass === null &&
        //   response.data.agt_aname === null
        // ) {
        //   this.setState({
        //     submitStatusAgentCheckControlUtility: "Error",
        //     submitMsgAgentCheckControlUtility: "AGENT ACCOUNT DOES NOT EXISTS",
        //   });
        // } else if (
        //   this.state.controlCheck === "3" &&
        //   response.data.acctclass !== "26"
        // ) {
        //   this.setState({
        //     submitStatusAgentCheckControlUtility: "Error",
        //     submitMsgAgentCheckControlUtility:
        //       "AGENT ACCOUNT NOT QUICK COLLECT",
        //     corectAgentFlag: false,
        //   });
        // } else if (
        //   this.state.controlCheck === "5" &&
        //   response.data.acctclass !== "26"
        // ) {
        //   this.setState({
        //     submitStatusAgentCheckControlUtility: "Error",
        //     submitMsgAgentCheckControlUtility:
        //       "AGENT ACCOUNT NOT QUICK COLLECT",
        //     corectAgentFlag: false,
        //   });
        // }
      });
  };

  onHandleSecondSubmitAgentCheckControlUtility = () => {
    if (!this.onValidateSecondAgentCheckControlUtility()) {
      return;
    }
    if (this.state.controlCheck === "1" && this.state.correct === "Y") {
      this.setState({
        firstUseFlag: true,
        corectAgentFlag: false,
      });
      if (this.state.checknumber) {
        axios
          .put(
            `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/firstuse/${this.state.agentAccount}/${this.state.checknumber}`,
            {},
            {
              headers: {
                ...getHeadersAgent().headers,
                Authorization: this.state.tokenCCU,
                userId: this.state.operatorIdCCU,
              },
            }
          )
          .then((res) => {
            if (
              res.status === 200 &&
              res.data.responseCode === "200"
            ) {
              this.setState({
                submitStatusAgentCheckControlUtility: "OK",
                submitMsgAgentCheckControlUtility: res.data.responseString,
              });
              this.onHandleClearAgentCheckControlUtility();
            } else if (
              res.status === 200 &&
              res.data.responseCode === "400"
            ) {
              this.setState({
                submitStatusAgentCheckControlUtility: "Error",
                submitMsgAgentCheckControlUtility: res.data.responseString,
              });
            } else {
              this.setState({
                submitStatusAgentCheckControlUtility: "Error",
                submitMsgAgentCheckControlUtility: res.data.responseString,
              });
              this.onHandleClearAgentCheckControlUtility();
            }
          });
      }
    } else if (this.state.controlCheck === "2" && this.state.correct === "Y") {
      const agentAccount = this.state.agentAccount;
      this.setState({
        corectAgentFlag: false,
      });
      this.props.history.push({
        pathname: "/agent-batch-check",
        state: agentAccount,
        data:this.state,
      });
    } else if (this.state.controlCheck === "3" && this.state.correct === "Y") {
      this.setState({
        addCheckFlag: true,
        corectAgentFlag: false,
      });
      if (this.state.checknumber) {
        if (this.state.checknumber > 0 && this.state.checknumber < 1000) {
          axios
            .post(
              `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/addcheck/${this.state.agentAccount}/${this.state.checknumber}`,
              {},
              {
                headers: {
                  ...getHeadersAgent().headers,
                  Authorization: this.state.tokenCCU,
                  userId: this.state.operatorIdCCU,
                },
              }
            )
            .then((response) => {
              if (
                response.status === 200 &&
                response.data.responseCode === "200"
              ) {
                this.setState({
                  submitStatusAgentCheckControlUtility: "OK",
                  submitMsgAgentCheckControlUtility:
                    response.data.responseString,
                });
                this.onHandleClearAgentCheckControlUtility();
              } else if (
                response.status === 200 &&
                response.data.responseCode === "400"
              ) {
                this.setState({
                  submitStatusAgentCheckControlUtility: "OK",
                  submitMsgAgentCheckControlUtility:
                    response.data.responseString,
                  continueFlag: true,
                  secondFlag: false,
                  addCheckFlag: false
                });
              } else {
                this.setState({
                  submitStatusAgentCheckControlUtility: "Error",
                  submitMsgAgentCheckControlUtility:
                    response.data.responseString,
                });
                this.onHandleClearAgentCheckControlUtility();
              }             
            });
        } else {
          this.setState({
            submitStatusAgentCheckControlUtility: "Error",
            submitMsgAgentCheckControlUtility:
              "Number Of Checks Value Should Be Between 1 And 1000",
          });
        }
      }
    } else if (this.state.controlCheck === "4" && this.state.correct === "Y") {
      axios
        .put(
          `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/reorderflag/${this.state.agentAccount}`,
          {},
          {
            headers: {
              ...getHeadersAgent().headers,
              Authorization: this.state.tokenCCU,
              userId: this.state.operatorIdCCU,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitStatusAgentCheckControlUtility: "OK",
              submitMsgAgentCheckControlUtility: response.data.responseString,
            });
          } else {
            this.setState({
              submitStatusAgentCheckControlUtility: "Error",
              submitMsgAgentCheckControlUtility: response.data.responseString,
            });
          }
          this.onHandleClearAgentCheckControlUtility();
        });
    } else if (this.state.controlCheck === "5" && this.state.correct === "Y") {
      this.setState({
        adjInvFlag: true,
        corectAgentFlag: false,
      });
      if (this.state.numberOfCheck) {
        axios
          .put(
            `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/checkinventory/${this.state.agentAccount}/${this.state.numberOfCheck}`,

            {},
            {
              headers: {
                ...getHeadersAgent().headers,
                Authorization: this.state.tokenCCU,
                userId: this.state.operatorIdCCU,
              },
            }
          )
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.responseCode === "200"
            ) {
              this.setState({
                submitStatusAgentCheckControlUtility: "OK",
                submitMsgAgentCheckControlUtility: response.data.responseString,
              });
              this.onHandleClearAgentCheckControlUtility();
            } else if (
              response.status === 200 &&
              response.data.responseCode === "400"
            ) {
              this.setState({
                submitStatusAgentCheckControlUtility: "Error",
                submitMsgAgentCheckControlUtility: response.data.responseString,
              });
            } else {
              this.setState({
                submitStatusAgentCheckControlUtility: "Error",
                submitMsgAgentCheckControlUtility: response.data.responseString,
              });
              this.onHandleClearAgentCheckControlUtility();
            }
          });
      }
    } else if (this.state.controlCheck === "6" && this.state.correct === "Y") {
      this.setState({
        rmvCheckFlag: true,
        corectAgentFlag: false,
      });
      if (this.state.remove) {
        if (this.state.remove === "Y") {
          axios
            .put(
              `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/removecheck/${this.state.agentAccount}`,
              {},
              {
                headers: {
                  ...getHeadersAgent().headers,
                  Authorization: this.state.tokenCCU,
                  userId: this.state.operatorIdCCU,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  submitStatusAgentCheckControlUtility: "OK",
                  submitMsgAgentCheckControlUtility:
                    response.data.responseString,
                });
              } else {
                this.setState({
                  submitStatusAgentCheckControlUtility: "Error",
                  submitMsgAgentCheckControlUtility:
                    response.data.responseString,
                });
              }
              this.onHandleClearAgentCheckControlUtility();
            });
        } else {
          this.setState({
            rmvCheckFlag: false,
            submitStatusAgentCheckControlUtility: "Error",
            submitMsgAgentCheckControlUtility: "No Checks Removed For Agent",
          });
          this.onHandleClearAgentCheckControlUtility();
        }
      }
    } else if (
      this.state.controlCheck === "7" &&
      this.state.checkControlUtilityValues.agt_bankcode === "MIM" &&
      this.state.correct === "Y"
    ) {
      const agentAccount = this.state.agentAccount;
      const values = this.state.checkControlUtilityValues;
      this.setState({
        corectAgentFlag: false,
      });
      this.props.history.push({
        pathname: "/micr-paper-support",
        state: agentAccount,
        data: values,
        accessGrantWriteCCU:this.state.accessGrantWriteCCU,
        tokenCCU:this.state.tokenCCU,
        operatorIdCCU:this.state.operatorIdCCU,
      });
    } else if (
      this.state.controlCheck === "7" &&
      this.state.checkControlUtilityValues.agt_bankcode !== "MIM" &&
      this.state.correct === "Y"
    ) {
      this.setState({
        submitStatusAgentCheckControlUtility: "Error",
        submitMsgAgentCheckControlUtility:
          "Agent Is Not Set To MICR, Please Set In AS400",
      });
    } else if (this.state.correct === "N") {
      this.onHandleClearAgentCheckControlUtility();
    }
  };

  onHandleThirdSubmitAgentCheckControlUtility =() =>{
    if(this.state.controlCheck === "3" && this.state.correct === "Y" && this.state.continueValue === "Y"){
      axios
      .post(
        `${process.env.REACT_APP_DB_VIEW_BASE_AGENT_SERVICE_URL}/v1/age/dbview/checkcontrolutility/addcheck/${this.state.agentAccount}/${this.state.checknumber}`,
        {},
        {
          headers: {
            ...getHeadersAgent().headers,
            Authorization: this.state.tokenCCU,
            userId: this.state.operatorIdCCU,
            continueValue:this.state.continueValue
          },
        }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.responseCode === "200"
        ) {
          this.setState({
            submitStatusAgentCheckControlUtility: "OK",
            submitMsgAgentCheckControlUtility:
              response.data.responseString,
              continueFlag:false
          });
        } else {
          this.setState({
            submitStatusAgentCheckControlUtility: "Error",
            submitMsgAgentCheckControlUtility:
              response.data.responseString,
          });
        }
        this.onHandleClearAgentCheckControlUtility();
      });
    }else{
      this.onHandleNewSelectionAgentCheckControlUtility();
    }
  }

  onHandleClearAgentCheckControlUtility = () => {
    setTimeout(
      () =>
        this.onHandleNewSelectionAgentCheckControlUtility(),
      5000
    );
  };

  onHandleNewSelectionAgentCheckControlUtility = () => {
    this.setState({
      submitStatusAgentCheckControlUtility: null,
      submitMsgAgentCheckControlUtility:"",
      correct: "Y",
      agentAccount: "",
      checkControlUtilityValues: {},
      controlCheck: "1",
      corectAgentFlag: false,
      secondFlag: false,
      firstUseFlag: false,
      addCheckFlag: false,
      adjInvFlag: false,
      rmvCheckFlag: false,
      enableBtn: false,
      checknumber: "",
      numberOfCheck: "",
      continueFlag:false,
      continueValue:"N"
    })
  };

  render() {
    return (
      <>
        <Header
          history={this.props.history}
          heading="Agent Check Control Utility"
        />
        <div className="container-fluid" data-test="component-network-list">
          <h4 className="card-title main-heading ">
            Agent Check Control Utility DETAILS
          </h4>
          <div className="form-group row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <div className="card">
                <div className="card-header bg-dark text-white card-header-custom">
                  Agent Check Control Utility Search
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value="1"
                            data-test="radio-firstUse"
                            checked={this.state.controlCheck === "1"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.firstUseFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="1"
                          >
                            FIRST USE
                          </label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value="2"
                            data-test="radio-check-status"
                            checked={this.state.controlCheck === "2"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.addCheckFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="2"
                          >
                            CHECK STATUS
                          </label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value={3}
                            data-test="radio-add-check-batch"
                            checked={this.state.controlCheck === "3"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="3"
                          >
                            ADD CHECK BATCH
                          </label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value={4}
                            data-test="radio-clear-reorder-flag"
                            checked={this.state.controlCheck === "4"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="4"
                          >
                            CLEAR REORDER FLAG
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value={5}
                            data-test="radio-adjust-inventory"
                            checked={this.state.controlCheck === "5"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="5"
                          >
                            ADJUST INVENTORY
                          </label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value={6}
                            data-test="radio-remove-check-control"
                            checked={this.state.controlCheck === "6"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="6"
                          >
                            REMOVE CHECK CONTROL
                          </label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value={7}
                            data-test="radio-micr-paper-support"
                            checked={this.state.controlCheck === "7"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="7"
                          >
                            MICR PAPER SUPPORT
                          </label>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="controlCheck"
                            value={8}
                            data-test="radio-exit-program"
                            checked={this.state.controlCheck === "8"}
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            disabled={
                              this.state.corectAgentFlag ||
                              this.state.secondFlag
                            }
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="8"
                          >
                            EXIT PROGRAM
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-1" />
                      <div className="col-lg-3">
                        <label
                          htmlFor="agentAccount"
                          className="font-weight-bold"
                        >
                          AGENT ACCOUNT:
                        </label>
                        <input
                          type="text"
                          value={this.state.agentAccount}
                          className="form-control form-control-sm"
                          name="agentAccount"
                          onChange={(e) => {
                            this.onChangeControlAgentCheckControlUtilityAgent(e);
                          }}
                          data-test="text-agentAccount"
                          placeholder=""
                          disabled={
                            this.state.corectAgentFlag ||
                            this.state.firstUseFlag
                          }
                        />
                      </div>
                      <div className="col-lg-1" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-1" />
                      {this.state.firstUseFlag && (
                        <div className="col-lg-3">
                          <label
                            htmlFor="checknumber"
                            className="font-weight-bold"
                          >
                            ENTER BEGINING CHECK NUMBER
                          </label>
                          <input
                            type="text"
                            value={this.state.checknumber}
                            className="form-control form-control-sm"
                            maxLength="10"
                            name="checknumber"
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            data-test="text-checknumber-1"
                            placeholder=""
                          />
                        </div>
                      )}
                      <div className="col-lg-1" />
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-1" />
                      {this.state.addCheckFlag && (
                        <div className="col-lg-3">
                          <label
                            htmlFor="checknumber"
                            className="font-weight-bold"
                          >
                            ENTER NUMBER OF CHECKS
                          </label>
                          <input
                            type="text"
                            value={this.state.checknumber}
                            className="form-control form-control-sm"
                            name="checknumber"
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            data-test="text-checknumber-2"
                            placeholder=""
                          />
                        </div>
                      )}
                      <div className="col-lg-1" />
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-1" />
                      {this.state.adjInvFlag && (
                        <div className="col-lg-3">
                          <label
                            htmlFor="numberOfCheck"
                            className="font-weight-bold"
                          >
                            ENTER NUMBER OF CHECKS ON HAND
                          </label>
                          <input
                            type="text"
                            value={this.state.numberOfCheck}
                            className="form-control form-control-sm"
                            name="numberOfCheck"
                            onChange={(e) => {
                              this.onChangeControlAgentCheckControlUtility(e);
                            }}
                            data-test="text-numberOfCheck"
                            placeholder=""
                          />
                        </div>
                      )}
                      <div className="col-lg-1" />
                    </div>
                
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-1" />
          </div>
          <div className="form-group row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
              <div className="card mar-top20">
                <div className="card-header bg-dark text-white card-header-custom">
                  Agent Check Control Utility Details
                </div>
                <div className="card-body card-body-custom">
                  <form>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <label
                          name="agt_aname"
                          value={this.state.checkControlUtilityValues.agt_aname}
                          className="font-weight-bold"
                        >
                          NAME: {this.state.checkControlUtilityValues.agt_aname}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <label htmlFor="agt_chkrd" className="font-weight-bold">
                          ACHKRD:
                          {this.state.checkControlUtilityValues.agt_chkrd}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="agt_chkminoh"
                          className="font-weight-bold"
                        >
                          ACHKMINOH:
                          {this.state.checkControlUtilityValues.agt_chkminoh}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="agt_chkctrll"
                          className="font-weight-bold"
                        >
                          ACHKCTRLL:
                          {this.state.checkControlUtilityValues.agt_chkctrll}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="agt_chktotoh"
                          className="font-weight-bold"
                        >
                          ACHKTOTOH:
                          {this.state.checkControlUtilityValues.agt_chktotoh}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1" />
                      <div className="col-lg">
                        <label
                          htmlFor="agt_chktotus"
                          className="font-weight-bold"
                        >
                          ACHKTOTUS:
                          {this.state.checkControlUtilityValues.agt_chktotus}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="agt_bankcode"
                          className="font-weight-bold"
                        >
                          ABANKCODE:
                          {this.state.checkControlUtilityValues.agt_bankcode}
                        </label>
                      </div>
                      <div className="col-lg">
                        <label
                          htmlFor="agt_encash"
                          className="font-weight-bold"
                        >
                          AENCASH:
                          {this.state.checkControlUtilityValues.agt_encash}
                        </label>
                      </div>
                      <div className="col-lg" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-1" />
          </div>
          <div className="form-group row">
          {this.state.corectAgentFlag && (
            <div className="col-lg text-center">
              <label className=" mar-right10 font-weight-bold">
                CORRECT AGENT :
              </label>

              <div className="form-check form-check-inline ">
                <input
                  className="form-check-input "
                  type="radio"
                  name="correct"
                  value="Y"
                  data-test="radio-correct-1"
                  checked={this.state.correct === "Y"}
                  onChange={(e) => {
                    this.onChangeControlAgentCheckControlUtility(e);
                  }}
                />

                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="Y"
                >
                  YES
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input "
                  type="radio"
                  name="correct"
                  value="N"
                  data-test="radio-correct-2"
                  checked={this.state.correct === "N"}
                  onChange={(e) => {
                    this.onChangeControlAgentCheckControlUtility(e);
                  }}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="N"
                >
                  NO
                </label>
              </div>
            </div>
          )}
          {this.state.continueFlag && (
            <div className="col-lg text-center">
              <label className=" mar-right10 font-weight-bold">
                CONTINUE:
              </label>

              <div className="form-check form-check-inline ">
                <input
                  className="form-check-input "
                  type="radio"
                  name="continueValue"
                  value="Y"
                  data-test="radio-continueValue-1"
                  checked={this.state.continueValue === "Y"}
                  onChange={(e) => {
                    this.onChangeControlAgentCheckControlUtility(e);
                  }}
                />

                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="Y"
                >
                  YES
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input "
                  type="radio"
                  name="continueValue"
                  value="N"
                  data-test="radio-continueValue-2"
                  checked={this.state.continueValue === "N"}
                  onChange={(e) => {
                    this.onChangeControlAgentCheckControlUtility(e);
                  }}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="N"
                >
                  NO
                </label>
              </div>
            </div>
          )}
        </div>
          <div className="form-group row">
          <div className="col-lg-1" />
          {this.state.rmvCheckFlag && (
            <div className="col-lg text-center">
              <label className=" mar-right10 font-weight-bold">
                ALL CHECKS WILL BE REMOVED. ARE YOU SURE YOU WANT TO CONTINUE?
              </label>

              <div className="form-check form-check-inline ">
                <input
                  className="form-check-input "
                  type="radio"
                  name="remove"
                  value="Y"
                  data-test="radio-remove-y"
                  checked={this.state.remove === "Y"}
                  onChange={(e) => {
                    this.onChangeControlAgentCheckControlUtility(e);
                  }}
                />

                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="Y"
                >
                  YES
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input "
                  type="radio"
                  name="remove"
                  value="N"
                  data-test="radio-remove"
                  checked={this.state.remove === "N"}
                  onChange={(e) => {
                    this.onChangeControlAgentCheckControlUtility(e);
                  }}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor="N"
                >
                  NO
                </label>
              </div>
            </div>
          )}
          <div className="col-lg-1" />
        </div>
        <div className="form-group row">
          <div className="col-lg-3" />
          <div className="col-lg-6 text-center mar-bottom5">
            {/* {this.state.secondFlag ? (
              <button
                type="button"
                className="btn btn-warning mar-right10"
                onClick={() =>
                  this.onHandleSecondSubmitAgentCheckControlUtility()
                }
                data-test="button-second-submit"
                disabled={!this.state.accessGrantWriteCCU}
              >
                SUBMIT
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-warning mar-right10"
                onClick={() => this.onHandleSubmitAgentCheckControlUtility()}
                data-test="button-first-submit"
                disabled={!this.state.accessGrantCCU}
              >
                SUBMIT
              </button>
            )} */}
              <button
                type="button"
                className="btn btn-warning mar-right10"
                onClick={() =>{
                  if(this.state.secondFlag === true){
                    this.onHandleSecondSubmitAgentCheckControlUtility()
                  }else if(this.state.continueFlag === true){
                    this.onHandleThirdSubmitAgentCheckControlUtility()
                  }else{
                    this.onHandleSubmitAgentCheckControlUtility()
                  }
                }}
                data-test="button-second-submit"
                disabled={!this.state.accessGrantWriteCCU}
              >
                SUBMIT
              </button>

            <button
              type="button"
              className="btn btn-warning mar-right10"
              onClick={this.onHandleNewSelectionAgentCheckControlUtility}
              data-test="button-new-selection"
              disabled={!this.state.accessGrantCCU}
            >
              NEW SELECTION
            </button>
          </div>
          <div className="col-lg-3" />
        </div>
        <div className="form-group row">
              <div className="col-lg-4" />
              <div className="col-lg-4">
                  {this.state.submitStatusAgentCheckControlUtility ===
                  "OK" && (
                  <div className="alert alert-success alert-padding notification text-center">
                     {this.state.submitMsgAgentCheckControlUtility}
                  </div>
                  )}
                  {this.state.submitStatusAgentCheckControlUtility ===
                  "Error" && (
                   <div className="alert alert-danger alert-padding notification text-center">
                      {this.state.submitMsgAgentCheckControlUtility}
                  </div>
                  )}
            </div>
            <div className="col-lg-4" />
        </div>
        </div>
      </>
    );
  }
}

export default withOktaAuth(CheckControlUtility);

