import { withOktaAuth } from "@okta/okta-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact as AgGridReactARAD } from "ag-grid-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { IconContext as IconContextARAD } from "react-icons";
import { AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import Header from "../../header/header";
import { getHeadersNRTAgtNW } from "../../utility/utility";

class SearchNrtAgentNetwork extends Component {

    state = {
        isLoading: false,
        isAuthorized: false,
        isWriteAllowed: false,
        userId: "",
        agentCycle: "",
        agentCycleActionSelection: "",
        agentNetworkNumberActionSelection: "",
        agentNetworkAcctNumberActionSelection: "",
        agentNetworkRoutingNumOrEmailActionSelection: "",
        showAgentNetworkDeleteConfirmModal: false,
        rows: null,
        enableBtn: false,
        gridApi: null,
        paginationValQueue: "25",
        defaultColumnDef: {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
        },
        actionResultIndicator: "",
        actionResultMessage: "",
        paginationOption: [25, 50, 100, 500],
        overlayLoadingTemplateARAD: '<span class="ag-overlay-loading-center">Loading...</span>',
        overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>',
        agentNetworkGridcolumns: [
            {
                headerName: "ACTION",
                field: "value",
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <span
                                className="cursor-pointer text-center" title="Edit"
                                onClick={() => this.onHandleEditAgentNetworkDetails(params)}
                            >
                                <IconContextARAD.Provider value={{ color: "#FDD017", size: "20px" }}>
                                    <AiTwotoneEdit />
                                </IconContextARAD.Provider>
                            </span>
                            <span
                                className="cursor-pointer text-center" title="Delete"
                                onClick={() => this.onHandleDeleteAgentNetworkDetailsConfirm(params)}
                            >
                                <IconContextARAD.Provider value={{ color: "#FDD017", size: "20px" }}>
                                    <AiTwotoneDelete />
                                </IconContextARAD.Provider>
                            </span>
                        </div>
                    );
                },
                sortable: false,
                filter: false,
                floatingFilter: false,
                width: "70px"
            },
            { field: "agentNetworkInfo.networkNumber", headerName: "Network ID", width: "125px" },
            { field: "agentNetworkInfo.networkSub", headerName: "Network Sub.", width: "125px" },
            { field: "agentNetworkInfo.networkAcctNum", headerName: "Network Country", width: "125px" },
            { field: "agentNetworkInfo.routingType", headerName: "Routing Type", width: "125px" },
            { field: "agentNetworkInfo.routingNumOrEmail", headerName: "Routing No / Email", width: "125px" },
            { field: "agentNetworkInfo.showStoreLocation", headerName: "Store Location", width: "125px" },
            { field: "agentNetworkInfo.showIntendedCntryDest", headerName: "Intended Cntry Dest", width: "125px" },
            { field: "agentNetworkInfo.showContLine", headerName: "Cont Line", width: "125px" },
            { field: "agentNetworkInfo.showCashInd", headerName: "Cash Ind", width: "125px" },
            { field: "agentNetworkInfo.show2000Ind", headerName: "2000 Ind", width: "125px" },
            { field: "agentNetworkInfo.showGWKInd", headerName: "GWK Ind", width: "125px" },
            { field: "agentNetworkInfo.showRevisionInd", headerName: "Revision Ind", width: "125px" },
            { field: "agentNetworkInfo.showCmbInd", headerName: "Cmb Ind", width: "125px" },
            { field: "agentNetworkInfo.showEmxInd", headerName: "Emx Ind", width: "125px" },
            { field: "agentNetworkInfo.showWucInd", headerName: "Wuc Ind", width: "125px" },
            { field: "agentNetworkInfo.showRdtInd", headerName: "Rdt Ind", width: "125px" },
            { field: "agentNetworkInfo.showPrdInd", headerName: "Prd Ind", width: "125px" },
            { field: "agentNetworkInfo.activationDate", headerName: "Activation Date", width: "125px" },
        ]
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;
        try {
            const id = jwt_decode(accessToken.value).hqintlId;
            const accessRole = jwt_decode(accessToken.value).appRoles;

            const isAuthorized = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_AGENT_NETWORK_UPDATE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS);
            this.setState({
                isAuthorized: isAuthorized,
                userId: id.substring(0, id.indexOf("@")),
            });

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
                accessRole.includes(process.env.REACT_APP_DB_VIEW_AGENT_NETWORK_UPDATE_ACCESS)) {
                this.setState({
                    isWriteAllowed: true
                });
            } else {
                this.setState({
                    isWriteAllowed: false
                });
            }
        } catch (error) {
            // invalid token format
        }
    }

    getAgentCyclesList = () => {
        let agentCycles = ["0300", "1000", "1900", "2400"];
        return agentCycles.map((item) => (
            <option key={item} value={item}>
                {item}
            </option>
        ));
    };

    onChangeControlAgentCycleUserSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onHandleClearSearchAgentNetworks = (e) => {
        this.setState({
            agentCycle: "",
            actionResultIndicator: "",
            actionResultMessage: "",
            rows: [],
            isLoading: false,
            overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>'
        });
    }

    onHandleSearchAgentNetworks = (e) => {
        if (!this.onValidateAgentCycleUserSelectionDetails()) {
            return;
        }

        this.setState({
            actionResultIndicator: "",
            actionResultMessage: "",
        });

        let submittedObj = {};
        submittedObj.agentNetworkCycle = this.state.agentCycle;
        this.setState({
            rows: [],
            isLoading: true
        });
        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_SERVICE_URL}/v1/ordnrt/dbview/agentnetwork/retrieve`,
            submittedObj,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        )
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        rows: response.data,
                        isLoading: false
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    onValidateAgentCycleUserSelectionDetails = () => {
        if (this.state.agentCycle === "") {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please select Agent Cycle",
            });
            return false;
        }
        return true;
    }

    onPageSizeChangedAgentCycleUserSelectionDetails = (e) => {
        this.setState({ paginationValQueue: e.target.value });
        this.state.gridApi.paginationSetPageSize(Number(e.target.value));
    };

    onGridReadyAgentCycleUserSelectionDetails = (params) => {
        if (params) {
            this.setState({ gridApi: params.api });
        }
        this.setState({
            rows: [],
            overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>'
        });
    };

    onHandleCreateAgentNetworks = (e) => {
        if (this.state.isWriteAllowed) {
            this.props.history.push("/nrt-create-agent-network");
        } else {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Permission Denied ! You Are Not Authorized To Perform This Action.",
            });
        }
    }

    onHandleEditAgentNetworkDetails = (params) => {
        if (this.state.isWriteAllowed) {
            this.props.history.push({ pathname: "/nrt-create-agent-network", state: { isEditAgentNetwork: true, agentNetwork: params.data } });
        } else {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Permission Denied ! You Are Not Authorized To Perform This Action.",
            });
        }
    }

    onHandleDeleteAgentNetworkDetailsConfirm = (param) => {
        if (this.state.isWriteAllowed) {
            this.setState({
                showAgentNetworkDeleteConfirmModal: true,
                agentCycleActionSelection: param.data.agentNetworkCycle,
                agentNetworkNumberActionSelection: param.data.agentNetworkInfo.networkNumber,
                agentNetworkAcctNumberActionSelection: param.data.agentNetworkInfo.networkAcctNum,
                agentNetworkRoutingNumOrEmailActionSelection: param.data.agentNetworkInfo.routingNumOrEmail,
                deletionSubmittedObj: param.data
            });
        } else {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Permission Denied ! You Are Not Authorized To Perform This Action.",
            });
        }
    }

    onHandleDeleteAgentNetworkDetailsConfirmModal = () => {
        return (
            <div>
                <Modal
                    show={this.state.showAgentNetworkDeleteConfirmModal}
                    animation={true}
                    size="md"
                    className="shadow-lg border"
                >
                    <Modal.Header className="bg-dark text-white text-center py-1">
                        <Modal.Title className="text-center">
                            <h5 className="modal-header-custom">
                                Are You Sure You Want To Delete Agent Network [{this.state.agentNetworkNumberActionSelection}] From Cycle [{this.state.agentCycleActionSelection}] ?
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="py-1 d-flex justify-content-center">
                        <div>
                            <Button
                                className="mar-right10"
                                variant="warning"
                                onClick={this.onHandleDeleteAgentNetworkDetails}
                                data-test="button-yes">
                                Yes
                            </Button>
                            <Button
                                className="mar-right10"
                                variant="warning"
                                onClick={() =>
                                    this.setState({
                                        showAgentNetworkDeleteConfirmModal: false,
                                        agentCycleActionSelection: "",
                                        agentNetworkNumberActionSelection: "",
                                        agentNetworkAcctNumberActionSelection: "",
                                        agentNetworkRoutingNumOrEmailActionSelection: "",
                                        deletionSubmittedObj: ""
                                    })
                                }
                                data-test="button-no">
                                No
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    onHandleDeleteAgentNetworkDetails = (param) => {
        let submittedObj = {};
        submittedObj = this.state.deletionSubmittedObj;

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_ORDER_NRT_SERVICE_URL}/v1/ordnrt/dbview/agentnetwork/delete`,
            submittedObj,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        )
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === "Agent Network Deleted Successfully !") {
                        this.setState({
                            actionResultIndicator: "OK",
                            actionResultMessage: response.data.result,
                        });

                        let tempArray = [...this.state.rows];
                        const index = tempArray.findIndex((row) =>
                        (row.agentNetworkInfo.networkNumber === this.state.agentNetworkNumberActionSelection
                            && row.agentNetworkInfo.networkAcctNum === this.state.agentNetworkAcctNumberActionSelection
                            && row.agentNetworkInfo.routingNumOrEmail === this.state.agentNetworkRoutingNumOrEmailActionSelection
                        ));
                        tempArray.splice(index, 1);

                        this.setState({
                            rows: tempArray
                        });

                    } else if (response.data.result === "No Network Found For Deletion !") {
                        this.setState({
                            actionResultIndicator: "Error",
                            actionResultMessage: response.data.result,
                        });
                    }
                    this.setState({
                        showAgentNetworkDeleteConfirmModal: false,
                        agentCycleActionSelection: "",
                        agentNetworkNumberActionSelection: "",
                        agentNetworkAcctNumberActionSelection: "",
                        agentNetworkRoutingNumOrEmailActionSelection: "",
                        deletionSubmittedObj: ""
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="NRT AGENT NETWORK MAINTENANCE"
                />

                <div className="container-fluid" data-test="">
                    {this.state.isAuthorized && (
                        <div>
                            <h4 className="card-title main-heading">
                                NRT AGENT NETWORK MAINTENANCE
                            </h4>

                            <div className="card">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    SEARCH AGENT NETWORK CYCLE
                                </div>
                                <div className="card-body card-body-custom">
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg">
                                                <label className="font-weight-bold">Agent Cycle</label>
                                                <select className="form-control form-control-sm"
                                                    name="agentCycle"
                                                    value={this.state.agentCycle}
                                                    data-test="select-agentCycle"
                                                    onChange={
                                                        this.onChangeControlAgentCycleUserSelectionDetails
                                                    }>
                                                    <option value="">Select</option>
                                                    {this.getAgentCyclesList()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-3" />
                                            <div className="col-lg-6 text-center mar-bottom5">
                                                {this.state.isLoading ?
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10" disabled>
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp;LOADING...
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10"
                                                            data-test="button-search"
                                                            onClick={this.onHandleSearchAgentNetworks}>
                                                            SEARCH
                                                        </button>
                                                    )}
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-clear"
                                                    onClick={this.onHandleClearSearchAgentNetworks}>
                                                    CLEAR
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-create"
                                                    onClick={this.onHandleCreateAgentNetworks}>
                                                    CREATE AGENT NETWORK
                                                </button>
                                            </div>
                                            <div className="col-lg-3">
                                                {this.state.actionResultIndicator ===
                                                    "Error" && (
                                                        <div className="alert alert-danger alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                                {this.state.actionResultIndicator ===
                                                    "OK" && (
                                                        <div className="alert alert-success alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card mar-top10">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    AGENT NETWORKS
                                </div>
                                <div className="card-body card-body-custom">
                                    <div className="pagination-dropdown">
                                        <label htmlFor="pagination-select-label" className="font-weight-bold">
                                            No of records per page:
                                        </label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={(e) => {
                                                this.onChangeControlAgentCycleUserSelectionDetails(e);
                                                this.onPageSizeChangedAgentCycleUserSelectionDetails(e);
                                            }}
                                            value={this.state.paginationValQueue}
                                            name="paginationDropdownQueue"
                                            data-test="select-dropdown"
                                        >
                                            {this.state.paginationOption.map(
                                                (val) => {
                                                    return (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <div
                                        className="ag-theme-balham"
                                        style={{ height: 250, width: "100%" }}
                                    >
                                        <AgGridReactARAD
                                            rowData={this.state.rows}
                                            columnDefs={this.state.agentNetworkGridcolumns}
                                            defaultColDef={this.state.defaultColumnDef}
                                            pagination={true}
                                            paginationPageSize={25}
                                            onGridReady={this.onGridReadyAgentCycleUserSelectionDetails}
                                            data-test="component-grid"
                                            enableCellTextSelection={true}
                                            overlayLoadingTemplate={
                                                this.state.overlayLoadingTemplateARAD
                                            }
                                            overlayNoRowsTemplate={
                                                this.state.overlayNoRowsTemplateARAD
                                            }
                                        ></AgGridReactARAD>

                                    </div>
                                </div>
                            </div>

                            {this.onHandleDeleteAgentNetworkDetailsConfirmModal()}
                        </div>
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withOktaAuth(SearchNrtAgentNetwork);