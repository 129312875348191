import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { getHeadersShared2 } from "../../utility/utility";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";

class FXValidDBMaintenance extends Component {
  state = {
    countryCode: "",
    currencyCode: "",
    accountType: "A",
    submitStatusFXDiscountSearch: null,
    submitMsgFXDiscountSearch: "",
    showFXDiscountSearchModal: false,
  };

  componentDidMount() {
    const accessToken = this.props.authState.accessToken;
    const tokenFXDS = accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const id = jwt_decode(accessToken.value).hqintlId;
      const operatorIdFXDS = id.replace(/\D/g, "");
      const accessGrantFXDS =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS
        ) ||
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS
        ) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_COUNTRYMAINTENANCE);
      const accessGrantWriteFXDS =
        accessRole.includes(
          process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS
        ) ||
        (accessRole.includes(process.env.REACT_APP_DB_VIEW_FXSPREADS) &&
          accessRole.includes(
            process.env.REACT_APP_DB_VIEW_COUNTRYMAINTENANCE
          ));

      this.setState(
        {
          accessToken: token,
          tokenFXDS: tokenFXDS,
          operatorIdFXDS: operatorIdFXDS,
          role: accessRole,
          accessGrantFXDS: accessGrantFXDS,
          accessGrantWriteFXDS: accessGrantWriteFXDS,
        },
        () => {
          if (!this.state.accessGrantFXDS) {
            this.setState({
              submitStatusFXDiscountSearch: "Error",
              submitMsgFXDiscountSearch: "Unauthorized!Access Is Denied.",
            });
          }
        }
      );
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }

  onChangeControlFxDiscountSearch = (e) => {    ;
    let regex = /[^a-zA-Z0-9]/;
    let val=e.target.value;
    if (!regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: val.toUpperCase(),
      });    }
    
  };
  handleDisplayFXDS = () => {
    if (!this.onValidateDisplayFXDS()) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fxdiscount/validation/${this.state.countryCode}/${this.state.accountType}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            currencyCode: this.state.currencyCode,
            Authorization: this.state.tokenFXDS,
            userId: this.state.operatorIdFXDS,
          },
        }
      )
      .then((response) => {
        if (response.data.errorString === null) {
          let data = {
            wuCountryCode: response.data.wuCountryCode,
            wuNumericCode: response.data.wuNumericCode,
            isoCountryCode: response.data.isoCountryCode,
            isoCountryName: response.data.isoCountryName,
            isoCurrencyCode: response.data.isoCurrencyCode,
            isoCurrencyName: response.data.isoCurrencyName,
            status: response.data.status,
            accountType: response.data.accountType,
            pricingCurrency: response.data.pricingCurrency,
            pricingCountryExt: response.data.pricingCountryExt,
            iso4217Num: response.data.iso4217Num,
            isoCurrencyMajorUnit: response.data.isoCurrencyMajorUnit,
            fxOpId: response.data.fxOpId,
            accessGrantWriteFXDS: this.state.accessGrantWriteFXDS,
            operatorIdFXDS: this.state.operatorIdFXDS,
            tokenFXDS: this.state.tokenFXDS,
          };
          this.props.history.push({
            pathname: "/fx-discount-details",
            state: data,
            mode: "display",
          });
        } else {
          this.setState({
            submitStatusFXDiscountSearch: "Error",
            submitMsgFXDiscountSearch: response.data.errorString,
          });
        }
      });
  };
  onHandleUpdateFXDS = () => {
    if (!this.onValidateFXDS()) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fxdiscount/validation/${this.state.countryCode}/${this.state.accountType}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            currencyCode: this.state.currencyCode,
            Authorization: this.state.tokenFXDS,
            userId: this.state.operatorIdFXDS,
          },
        }
      )
      .then((response) => {
        if (response.data.errorString === null) {
          let data = {
            wuCountryCode: response.data.wuCountryCode,
            wuNumericCode: response.data.wuNumericCode,
            isoCountryCode: response.data.isoCountryCode,
            isoCountryName: response.data.isoCountryName,
            isoCurrencyCode: response.data.isoCurrencyCode,
            isoCurrencyName: response.data.isoCurrencyName,
            status: response.data.status,
            accountType: response.data.accountType,
            pricingCurrency: response.data.pricingCurrency,
            pricingCountryExt: response.data.pricingCountryExt,
            iso4217Num: response.data.iso4217Num,
            isoCurrencyMajorUnit: response.data.isoCurrencyMajorUnit,
            fxOpId: response.data.fxOpId,
            accessGrantWriteFXDS: this.state.accessGrantWriteFXDS,
            operatorIdFXDS: this.state.operatorIdFXDS,
            tokenFXDS: this.state.tokenFXDS,
          };
          this.props.history.push({
            pathname: "/fx-discount-details",
            state: data,
            mode: "update",
          });
        } else {
          this.setState({
            submitStatusFXDiscountSearch: "Error",
            submitMsgFXDiscountSearch: response.data.errorString,
          });
        }
      });
  };
  onHandleInsertFXDS = () => {
    if (!this.onValidateFXDS()) {
      return;
    }
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHAREDEXT_SERVICE_URL}/v1/sha/dbview/fxdiscount/currencyvalidation/${this.state.currencyCode}/${this.state.countryCode}/${this.state.accountType}`,
        {
          headers: {
            ...getHeadersShared2().headers,
            Authorization: this.state.tokenFXDS,
            userId: this.state.operatorIdFXDS,
          },
        }
      )
      .then((response) => {
        if (response.data.responseCode === "S") {
          let data = {
            wuCountryCode: this.state.countryCode,
            isoCurrencyCode: this.state.currencyCode,
            isoCurrencyName: response.data.currencyName,
            status: "A",
            accountType: this.state.accountType,
            iso4217Num: response.data.iso4217Num,
            isoCurrencyMajorUnit: response.data.isoCurrencyMajorUnit,
            accessGrantWriteFXDS: this.state.accessGrantWriteFXDS,
            operatorIdFXDS: this.state.operatorIdFXDS,
            tokenFXDS: this.state.tokenFXDS,
          };
          this.props.history.push({
            pathname: "/fx-discount-details",
            state: data,
            mode: "insert",
          });
        } else {
          this.setState({
            submitStatusFXDiscountSearch: "Error",
            submitMsgFXDiscountSearch: response.data.responseString,
          });
        }
      });
  };
  onValidateFXDS = () => {
    if (this.state.countryCode === "") {
      this.setState({
        submitStatusFXDiscountSearch: "Error",
        submitMsgFXDiscountSearch: "Please Enter WU Country Code",
      });
      return false;
    }
    if (this.state.currencyCode === "") {
      this.setState({
        submitStatusFXDiscountSearch: "Error",
        submitMsgFXDiscountSearch: "Please Enter ISO Currency Code",
      });
      return false;
    }
    if (this.state.countryCode.length !== 3) {
      this.setState({
        submitStatusFXDiscountSearch: "Error",
        submitMsgFXDiscountSearch: "Please Enter Valid WU Country Code",
      });
      return false;
    }
  
    return true;
  };
  onValidateDisplayFXDS = () => {
    if (this.state.countryCode === "") {
      this.setState({
        submitStatusFXDiscountSearch: "Error",
        submitMsgFXDiscountSearch: "Please Enter WU Country Code",
      });
      return false;
    }
    if (this.state.countryCode.length !== 3) {
      this.setState({
        submitStatusFXDiscountSearch: "Error",
        submitMsgFXDiscountSearch: "Please Enter Valid WU Country Code",
      });
      return false;
    }
   
    return true;
  };
  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid row">
          <div className="col-lg-3"></div>
          <div className="card-body col-lg-6">
            <h4 className="card-title main-heading ">
              F/X Discount Validation Database Maintenance
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                F/X DISCOUNT SEARCH
              </div>
              <div className="card-body card-body-custom">
                
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="countryCode" className="font-weight-bold">
                      WU Country Code
                    </label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.countryCode}
                      className="form-control form-control-sm"
                      name="countryCode"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountSearch(e);
                      }}
                      data-test="text-countryCode"
                      placeholder="WU Country Code"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="currencyCode" className="font-weight-bold">
                      ISO Currency Code
                    </label>
                    <input
                      type="text"
                      value={this.state.currencyCode}
                      maxLength="3"
                      className="form-control form-control-sm"
                      name="currencyCode"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountSearch(e);
                      }}
                      data-test="text-currencyCode"
                      placeholder="ISO Currency Code"
                    />
                  </div>
                </div>
                <div className="formm-group row">
                  <div className="col-lg">
                    <label htmlFor="accountType" className="font-weight-bold">
                      Account Type
                    </label>
                    <select
                      className="form-control  form-control-sm "
                      name="accountType"
                      data-test="select-accountType"
                      onChange={(e) => {
                        this.onChangeControlFxDiscountSearch(e);
                      }}
                      value={this.state.accountType}
                    >
                      <option value="A">A</option>
                      <option value="C">C</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg text-center mar-bottom5 mar-top20">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-display"
                      onClick={this.handleDisplayFXDS}
                      disabled={!this.state.accessGrantFXDS}
                    >
                      DISPLAY
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-update"
                      onClick={() => {
                        this.onHandleUpdateFXDS();
                      }}
                      disabled={!this.state.accessGrantWriteFXDS}
                    >
                      UPDATE
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-insert"
                      onClick={() => {
                        this.onHandleInsertFXDS();
                      }}
                      disabled={!this.state.accessGrantWriteFXDS}
                    >
                      INSERT
                    </button>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    {this.state.submitStatusFXDiscountSearch === "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgFXDiscountSearch}
                      </div>
                    )}
                    {this.state.submitStatusFXDiscountSearch === "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgFXDiscountSearch}
                      </div>
                    )}
                  </div>
                </div>
                {/* {this.getFXDiscountSearchModal()} */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withOktaAuth(FXValidDBMaintenance);
