import { withOktaAuth } from "@okta/okta-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact as AgGridReactARAD } from "ag-grid-react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { IconContext as IconContextARAD } from "react-icons";
import { AiTwotoneDelete } from "react-icons/ai";
import Header from "../../header/header";
import { getHeadersNRTAgtNW } from "../../utility/utility";

class EditConfigManagementAccess extends Component {

    state = {
        isLoading: false,
        isAuthorized: false,
        isAllowed: false,
        userId: "",
        employeeId: "",
        actionResultIndicator: "",
        actionResultMessage: "",
        showDeleteEmployeeConfigAccessConfirmModal: false,
        rows: null,
        enableBtn: false,
        gridApi: null,
        paginationValQueue: "25",
        defaultColumnDef: {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
        },
        paginationOption: [25, 50, 100, 500],
        overlayLoadingTemplateARAD: '<span class="ag-overlay-loading-center">Loading...</span>',
        overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>',
        configAccessGridcolumns: [
            {
                headerName: "ACTION",
                field: "value",
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <span
                                className="cursor-pointer text-center" title="Delete"
                                onClick={() => this.onHandleDeleteEmployeeConfigAccessConfirm(params)}
                            >
                                <IconContextARAD.Provider value={{ color: "#FDD017", size: "20px" }}>
                                    <AiTwotoneDelete />
                                </IconContextARAD.Provider>
                            </span>
                        </div>
                    );
                },
                sortable: false,
                filter: false,
                floatingFilter: false,
                width: "100px"
            },
            { field: "employeeId", headerName: "Employee ID", width: "1250px" },
        ]
    };

    componentDidMount() {
        const accessToken = this.props.authState.accessToken;
        try {
            const id = jwt_decode(accessToken.value).hqintlId;
            const accessRole = jwt_decode(accessToken.value).appRoles;

            if (accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS)) {
                this.setState({
                    isAuthorized: true,
                    userId: id.substring(0, id.indexOf("@")),
                    isAllowed: true
                });
                this.onHandleGetConfigAccess();
            }
        } catch (error) {
            // invalid token format
        }
    }

    onHandleGetConfigAccess = (e) => {
        this.onHandleClearAddConfigAccess();
        this.setState({
            rows: [],
            isLoading: true
        });
        axios.get(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/access/retrieve`,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        )
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        rows: response.data,
                        isLoading: false
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    onChangeControlUserSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onHandleClearAddConfigAccess = (e) => {
        this.setState({
            isLoading: false,
            employeeId: "",
            actionResultIndicator: "",
            actionResultMessage: "",
            showDeleteEmployeeConfigAccessConfirmModal: false,
        });
    }

    onChangeControlEmployeeConfigAccessSelectionDetails = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onPageSizeChangedEmployeeConfigAccessSelectionDetails = (e) => {
        this.setState({ paginationValQueue: e.target.value });
        this.state.gridApi.paginationSetPageSize(Number(e.target.value));
    };

    onGridReadyEmployeeConfigAccessSelectionDetails = (params) => {
        if (params) {
            this.setState({ gridApi: params.api });
        }
        this.setState({
            rows: [],
            overlayNoRowsTemplateARAD: '<span style="">No Records Found</span>'
        });
    };

    onHandleAddConfigAccess = (e) => {

        if (!this.onValidateAddConfigAccessValidation()) {
            return;
        }

        this.setState({
            actionResultIndicator: "",
            actionResultMessage: "",
            isLoading: true
        });

        let configAccess = {};
        configAccess.employeeId = this.state.employeeId;

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/access/create`,
            configAccess,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        ).then((response) => {
            if (response.status === 200) {
                this.onHandleClearAddConfigAccess();
                if (response.data.result === "Config Access Created Successfully !") {
                    this.setState({
                        actionResultIndicator: "OK",
                        actionResultMessage: response.data.result,
                        isLoading: false
                    });
                    this.onHandleGetConfigAccess();
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    onValidateAddConfigAccessValidation = (e) => {
        if (this.state.employeeId === "") {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Please Enter Mandatory Fields !",
            });
            return false;
        }
        if (this.state.rows.some(item => this.state.employeeId === item.employeeId)) {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Config Access Already Exist !",
            });
            return false;
        }
        return true;
    }

    onHandleDeleteEmployeeConfigAccessConfirm = (param) => {
        if (this.state.isAllowed) {
            this.setState({
                showDeleteEmployeeConfigAccessConfirmModal: true,
                configAccessSelection: param.data.employeeId
            });
        } else {
            this.setState({
                actionResultIndicator: "Error",
                actionResultMessage: "Permission Denied ! You Are Not Authorized To Perform This Action.",
            });
        }
    }

    onHandleDeleteEmployeeConfigAccessConfirmModal = () => {
        return (
            <div>
                <Modal
                    show={this.state.showDeleteEmployeeConfigAccessConfirmModal}
                    animation={true}
                    size="md"
                    className="shadow-lg border"
                >
                    <Modal.Header className="bg-dark text-white text-center py-1">
                        <Modal.Title className="text-center">
                            <h5 className="modal-header-custom">
                                Are You Sure You Want To Revoke Config Access For Employee ID [{this.state.configAccessSelection}] ?
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer className="py-1 d-flex justify-content-center">
                        <div>
                            <Button
                                className="mar-right10"
                                variant="warning"
                                onClick={this.onHandleDeleteConfigAccess}
                                data-test="button-yes">
                                Yes
                            </Button>
                            <Button
                                className="mar-right10"
                                variant="warning"
                                onClick={() =>
                                    this.setState({
                                        showDeleteEmployeeConfigAccessConfirmModal: false,
                                        configAccessSelection: ""
                                    })
                                }
                                data-test="button-no">
                                No
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    onHandleDeleteConfigAccess = (param) => {
        let submittedObj = {};
        submittedObj.employeeId = this.state.configAccessSelection;

        axios.post(
            `${process.env.REACT_APP_DB_VIEW_BASE_URL}/v1/ordnrt/dbview/config/access/delete`,
            submittedObj,
            getHeadersNRTAgtNW(this.props.authState !== null ? this.props.authState.accessToken.value : "", this.state.userId)
        )
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.result === "Config Access Deleted Successfully !") {
                        this.setState({
                            actionResultIndicator: "OK",
                            actionResultMessage: response.data.result,
                        });

                        let tempArray = [...this.state.rows];
                        const index = tempArray.findIndex((row) =>
                            (row.employeeId === this.state.configAccessSelection));
                        tempArray.splice(index, 1);

                        this.setState({
                            rows: tempArray
                        });

                    }
                    this.setState({
                        showDeleteEmployeeConfigAccessConfirmModal: false,
                        configAccessSelection: ""
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    onHandleBackToConfigQueryManagement = (e) => {
        this.props.history.push("/config-management-query-executor");
    }

    render() {
        return (
            <>
                <Header
                    history={this.props.history}
                    heading="CONFIG ACCESS MANAGEMENT"
                />

                <div className="container-fluid" data-test="">
                    {this.state.isAuthorized && (
                        <div>
                            <h4 className="card-title main-heading">
                                CONFIG ACCESS MANAGEMENT
                            </h4>

                            <div className="card">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    ADD CONFIG ACCESS
                                </div>
                                <div className="card-body card-body-custom">
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg">
                                                <label htmlFor="employeeId" className="font-weight-bold">*Employee ID</label>
                                                <input
                                                    type="number"
                                                    maxLength="10"
                                                    className="form-control form-control-sm"
                                                    name="employeeId"
                                                    value={this.state.employeeId}
                                                    data-test="input-employeeId"
                                                    onChange={this.onChangeControlUserSelectionDetails}
                                                    placeholder="Employee ID" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-lg-3" />
                                            <div className="col-lg-6 text-center mar-bottom5">
                                                {this.state.isLoading ?
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10" disabled>
                                                            <span className="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp;LOADING...
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-warning mar-right10"
                                                            data-test="button-add"
                                                            onClick={this.onHandleAddConfigAccess}>
                                                            ADD
                                                        </button>
                                                    )}
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-clear"
                                                    onClick={this.onHandleClearAddConfigAccess}>
                                                    CLEAR
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-warning mar-right10"
                                                    data-test="button-back"
                                                    onClick={this.onHandleBackToConfigQueryManagement}>
                                                    BACK TO CONFIG QUERY MANAGEMENT
                                                </button>
                                            </div>
                                            <div className="col-lg-3">
                                                {this.state.actionResultIndicator ===
                                                    "Error" && (
                                                        <div className="alert alert-danger alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                                {this.state.actionResultIndicator ===
                                                    "OK" && (
                                                        <div className="alert alert-success alert-padding">
                                                            {this.state.actionResultMessage}
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card mar-top10">
                                <div className="card-header bg-dark text-white card-header-custom">
                                    EMPLOYEE LIST
                                </div>
                                <div className="card-body card-body-custom">
                                    <div className="pagination-dropdown">
                                        <label htmlFor="pagination-select-label" className="font-weight-bold">
                                            No of records per page:
                                        </label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={(e) => {
                                                this.onChangeControlEmployeeConfigAccessSelectionDetails(e);
                                                this.onPageSizeChangedEmployeeConfigAccessSelectionDetails(e);
                                            }}
                                            value={this.state.paginationValQueue}
                                            name="paginationDropdownQueue"
                                            data-test="select-dropdown"
                                        >
                                            {this.state.paginationOption.map(
                                                (val) => {
                                                    return (
                                                        <option key={val} value={val}>
                                                            {val}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <div
                                        className="ag-theme-balham"
                                        style={{ height: 250, width: "100%" }}
                                    >
                                        <AgGridReactARAD
                                            rowData={this.state.rows}
                                            columnDefs={this.state.configAccessGridcolumns}
                                            defaultColDef={this.state.defaultColumnDef}
                                            pagination={true}
                                            paginationPageSize={25}
                                            onGridReady={this.onGridReadyEmployeeConfigAccessSelectionDetails}
                                            data-test="component-grid"
                                            enableCellTextSelection={true}
                                            overlayLoadingTemplate={
                                                this.state.overlayLoadingTemplateARAD
                                            }
                                            overlayNoRowsTemplate={
                                                this.state.overlayNoRowsTemplateARAD
                                            }
                                        ></AgGridReactARAD>

                                    </div>
                                </div>
                            </div>

                            {this.onHandleDeleteEmployeeConfigAccessConfirmModal()}
                        </div>
                    )}

                    {!this.state.isAuthorized && (
                        <div className="row">
                            <div className="col-lg-4 mar-top10"></div>
                            <div className="col-lg-4 mar-top10">
                                <div className="container-fluid alert alert-danger alert-padding">
                                    Permission Denied ! You Are Not Authorized To Perform This Action.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withOktaAuth(EditConfigManagementAccess);