import React, { Component } from "react";
import Header from "../../header/header";
import axios from "axios";
import { withOktaAuth } from "@okta/okta-react";
import jwt_decode from "jwt-decode";
import ReactExport from "react-data-export";
import { getHeadersShared } from "../../utility/utility";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class CountryDatabaseMaintenance extends Component {
  state = {
    countryCode: "",
    countryName: "",
    accountPrefix: "",
    showCountryMaintenanceConfirm: false,
    submitStatusCountryMaintenance: null,
    submitMsgCountryMaintenance: "",
  };

  onChangeControlCountryMaintenance = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val,
    });
  };
  componentDidMount(){    
    const accessToken = this.props.authState.accessToken;
    const tokenCM = accessToken.value;
    try {
      const token = jwt_decode(accessToken.value);
      const accessRole = jwt_decode(accessToken.value).appRoles;
      const id=jwt_decode(accessToken.value).hqintlId;
      const operatorIdCM = id.replace(/\D/g,'');
      const accessGrantCM =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READ_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_COUNTRYADMIN);
      const accessCountryMaint = accessRole.includes(process.env.REACT_APP_DB_VIEW_COUNTRYMAINTENANCE);
      const accessGrantedCM =
        accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_COUNTRYADMIN);
      const accessGrantWriteCM = accessRole.includes(process.env.REACT_APP_DB_VIEW_SUPERUSER_READWRITE_ACCESS) ||
        accessRole.includes(process.env.REACT_APP_DB_VIEW_COUNTRYADMIN);

      this.setState({
        accessToken: token,
        tokenCM:tokenCM,
        operatorIdCM:operatorIdCM,
        role: accessRole,
        accessGrantCM: accessGrantCM,
        accessCountryMaint:accessCountryMaint,
        accessGrantWriteCM: accessGrantWriteCM,
        accessGrantedCM:accessGrantedCM,
      }, () => {
        if(!this.state.accessGrantCM && !this.state.accessCountryMaint) { 
          this.setState({
            submitStatusCountryMaintenance: 'Error',
            submitMsgCountryMaintenance: "Unauthorized!Access Is Denied.",
          });
        }
      });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }
  onChangeControlCountryMaintenanceCharAndNumber = (e) => {
    let val = e.target.value.toUpperCase();
    this.setState({
      [e.target.name]: val.replace(/[^a-zA-Z0-9]/, ""),
    });
  };

  onValidateCountryMaintenance = () => {
    if (
      this.state.countryCode === "" &&
      this.state.countryName === "" &&
      this.state.accountPrefix === ""
    ) {
      this.setState({
        submitStatusCountryMaintenance: "Error",
        submitMsgCountryMaintenance: "Please Enter Value For Country Name or Alpha Country Code",
      });
      return false;
    }
    let exp = /^[0-9]|[A-Z]+$/;
    if (this.state.countryCode !== "" && !this.state.countryCode.match(exp)){
      this.setState({
        submitStatusCountryMaintenance: "Error",
        submitMsgCountryMaintenance: "Please Enter Either Country Code Or Numeric Code",
      });
      return false;
    }
    if (this.state.countryCode !== "" && this.state.countryCode.length !==3) {
      this.setState({
        submitStatusCountryMaintenance: "Error",
        submitMsgCountryMaintenance: "Please Enter 3 Digit Value Of Country Code",
      });
      return false;
    }
    if (this.state.accountPrefix !== "" && this.state.accountPrefix.length !==2) {
      this.setState({
        submitStatusCountryMaintenance: "Error",
        submitMsgCountryMaintenance: "Please Enter 2 Digit Value Of Account Prefix",
      });
      return false;
    }
    return true;
  };

  onHandleUpdateCountryMaintenance = () => {
    if (!this.onValidateCountryMaintenance()) {
      return;
    }
    if (this.state.countryCode.length === 3 || this.state.countryName) {
      let upperCountryCode = this.state.countryCode.toUpperCase();
      this.setState({ countryCode: upperCountryCode });

      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/country`,
          {
            headers: {
              ...getHeadersShared().headers,
              countryCode: this.state.countryCode,
              countryName: this.state.countryName,
              Authorization:this.state.tokenCM,
              userId: this.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          if (response.data.countryCode === null) {
            this.setState({
              submitStatusCountryMaintenance: "Error",
              submitMsgCountryMaintenance: "Country Does Not Exist",
            });
          } else {
            this.props.history.push({
              pathname: "/country-details",
              state: {
                mode: "update",
                data: {
                  countryCode: this.state.countryCode,
                  countryName: this.state.countryName,
                },
                state: this.state,
              },
            });
          }
        });
    } else if (this.state.accountPrefix.length === 2) {
      this.props.history.push({
        pathname: "/account-details",
        state: this.state,
        
      });
    }
  };

  onHandleAddCountryMaintenance = () => {
    if (!this.onValidateCountryMaintenance()) {
      return;
    }
    const countrycode = this.state.countryCode;
    if (this.state.countryCode !== "" || this.state.countryName !== "") {
      axios
        .get(
          `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/country`,
          {
            headers: {
              ...getHeadersShared().headers,
              countryCode: this.state.countryCode,
              countryName: this.state.countryName,
              Authorization:this.state.tokenCM,
              userId: this.state.operatorIdCM,
            },
          }
        )
        .then((response) => {
          if (response.data.countryCode !== null) {
            this.setState({
              submitStatusCountryMaintenance: "Error",
              submitMsgCountryMaintenance: "Country Already Exist !",
            });
          } else {
            const data = {
              countryCode: countrycode,
              countryName: this.state.countryName,
            };
            this.props.history.push({
              pathname: "/country-details",
              state: { mode: "add", data: data, state:this.state },
            });
          }
        });
    } else {
      this.props.history.push({
        pathname: "/account-details",
        state: this.state,
      });
    }
  };

  onHandleCorridorReport = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/corridorreport`,
        {
          headers: {
            ...getHeadersShared().headers,            
            Authorization:this.state.tokenCM,
            userId: this.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        this.setState(
          {
            corridorRows: response.data.records,
          },
          () => {
            this.excelrefCorridorReport.handleDownload();
          }
        );
      });
  };

  onHandleCountryReport = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/countryreport`,
        {
          headers: {
            ...getHeadersShared().headers,            
            Authorization:this.state.tokenCM,
            userId: this.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        this.setState(
          {
            countryRows: response.data.records,
          },
          () => {
            this.excelrefCountryReport.handleDownload();
          }
        );
      });
  };

  onHandleChangeReport = () => {
    axios
      .get(
        `${process.env.REACT_APP_DB_VIEW_BASE_SHARED_SERVICE_URL}/v1/sha/dbview/countrymaintenance/changereport`,
        {
          headers: {
            ...getHeadersShared().headers,            
            Authorization:this.state.tokenCM,
            userId: this.state.operatorIdCM,
          },
        }
      )
      .then((response) => {
        this.setState(
          {
            changeRows: response.data.records,
          },
          () => {
            this.excelrefChangeReport.handleDownload();
          }
        );
      });
  };

  getExcelChangeColumn = () => {
    let valCDM = [
      { changeLabel: "OPER ID", changeVal: "operatorId" },
      { changeLabel: "TERMINAL ID", changeVal: "terminalId" },
      { changeLabel: "TS", changeVal: "ts" },
      { changeLabel: "SITE", changeVal: "site" },
      { changeLabel: "ACTION", changeVal: "action" },
      { changeLabel: "SUCCESS/FAIL", changeVal: "successFail" },
      { changeLabel: "SCREEN NO", changeVal: "screenNo" },
      { changeLabel: "RECORD IND", changeVal: "recordInd" },
      { changeLabel: "DATA", changeVal: "data" },
      { changeLabel: "LAST PROGRAM ID", changeVal: "lastProgramId" },
      { changeLabel: "DATA CAPTURE ID", changeVal: "dataCaptureInd" },
      { changeLabel: "RECORD PRIM KEY", changeVal: "recordPrimKey" },
    ];
    return valCDM.map((item) => (
      <ExcelColumn label={item.changeLabel} value={item.changeVal} />
    ));
  };

  getExcelCorridorColumn = () => {
    let valECC = [
      { corridorLabel: "ORIG CC", corridorVal: "orginCountryCode" },
      { corridorLabel: "DEST CC", corridorVal: "destCountryCode" },
      { corridorLabel: "FIX", corridorVal: "fix" },
      { corridorLabel: "MTCN REQ", corridorVal: "mtcnReq" },
      { corridorLabel: "HARDFIX", corridorVal: "hardFix" },
      { corridorLabel: "OPER", corridorVal: "operator" },
      { corridorLabel: "TERM", corridorVal: "term" },
      { corridorLabel: "SITE", corridorVal: "site" },
      { corridorLabel: "DATE TIME", corridorVal: "dateTime" },
    ];
    return valECC.map((item) => (
      <ExcelColumn label={item.corridorLabel} value={item.corridorVal} />
    ));
  };

  getExcelCountryColumn = () => {
    let valCountryColumn = [
      { countryLabel: "COUNTRY CODE", countryValue: "countryCode" },
      { countryLabel: "NUMERIC Code", countryValue: "numericCode" },
      { countryLabel: "ISO CODE", countryValue: "isoCode" },
      { countryLabel: "STATUS", countryValue: "status" },
      { countryLabel: "CNTRY NAME", countryValue: "countryName" },
      { countryLabel: "CURRENCY", countryValue: "currency" },
      { countryLabel: "ROUTE TYPE", countryValue: "routeType" },
      { countryLabel: "ROUNDING AMT ", countryValue: "roudingAmount" },
      { countryLabel: "CCAUTHQ", countryValue: "ccAuthQ" },
      { countryLabel: "ROUTE", countryValue: "route" },
      { countryLabel: "TEST QUESTION", countryValue: "testQuestion" },
      { countryLabel: "FIX ON SEND", countryValue: "fixOnSend" },
      { countryLabel: "FIX ON RCV", countryValue: "fixOnReceive" },
      { countryLabel: "FIX ON INTRA", countryValue: "fixOnIntraCountry" },
      { countryLabel: "QQC MAX", countryValue: "qqcMax" },
      { countryLabel: "BEST BUY", countryValue: "bestBuy" },
      { countryLabel: "PAYOUT MAX", countryValue: "payoutMax" },
      { countryLabel: "US DISCOUNT", countryValue: "usDiscount" },
      { countryLabel: "NON US DISCOUNT", countryValue: "nonUsDiscount" },
      { countryLabel: "PURGE PERIOD", countryValue: "purgePeriod" },
      {
        countryLabel: "EX RATE SINGLE/BID/ASK",
        countryValue: "exRateSingleBidAsk",
      },
      {
        countryLabel: "EX RATE FX ADJUST",
        countryValue: "exRatefxAdjust",
      },
      { countryLabel: "UPDATE HISTORY DATE", countryValue: "updateHistoryDate" },
      { countryLabel: "UPDATE HISTORY OPERATOR", countryValue: "updateHistoryOperator" },
      { countryLabel: "TUPDATE HISTORY TERMINAL", countryValue: "updateHistoryTerminal" },
      { countryLabel: "XRATE", countryValue: "xrate" },
      { countryLabel: "ARATE", countryValue: "arate" },
    ];
    return valCountryColumn.map((item) => (
      <ExcelColumn label={item.countryLabel} value={item.countryValue} />
    ));
  };

  render() {
    return (
      <>
        <Header history={this.props.history} heading="COUNTRY MAINTENANCE" />
        <div className="container-fluid">
          <div className="card-body country-container">
            <h4 className="card-title main-heading ">
              COUNTRY DATABASE MAINTENANCE
            </h4>
            <div className="card">
              <div className="card-header bg-dark text-white card-header-custom">
                INTERNATIONAL DATABASE MAINTENANCE
              </div>
              <div className="card-body card-body-custom">
                <div className="form-group row">
                  <div className="col-lg">
                    {this.state.submitStatusCountryMaintenance === "OK" && (
                      <div className="alert alert-success alert-padding notification text-center">
                        {this.state.submitMsgCountryMaintenance} 
                      </div>
                    )}
                    {this.state.submitStatusCountryMaintenance === "Error" && (
                      <div className="alert alert-danger alert-padding notification text-center">
                        {this.state.submitMsgCountryMaintenance}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="countryCode" className="font-weight-bold">Alpha Country Code</label>
                    <input
                      type="text"
                      maxLength="3"
                      value={this.state.countryCode}
                      className="form-control form-control-sm"
                      name="countryCode"
                      onChange={(e) => {
                        this.onChangeControlCountryMaintenanceCharAndNumber(e);
                      }}
                      data-test="text-countryCode"
                      placeholder="Alpha Country Code"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="countryName" className="font-weight-bold">Country Name</label>
                    <input
                      type="text"
                      maxLength="40"
                      value={this.state.countryName}
                      className="form-control form-control-sm"
                      name="countryName"
                      onChange={(e) => {
                        this.onChangeControlCountryMaintenance(e);
                      }}
                      data-test="text-countryName"
                      placeholder="Country Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg">
                    <label htmlFor="accountPrefix" className="font-weight-bold">Account Prefix</label>
                    <input
                      type="text"
                      maxLength="2"
                      value={this.state.accountPrefix}
                      className="form-control form-control-sm"
                      name="accountPrefix"
                      onChange={(e) => {
                        this.onChangeControlCountryMaintenance(e);
                      }}
                      data-test="text-accountPrefix"
                      placeholder="Account Prefix"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg text-center mar-bottom5 mar-top20">
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-update"
                      onClick={this.onHandleUpdateCountryMaintenance}
                      disabled={!this.state.accessGrantCM && !this.state.accessCountryMaint}
                    >
                      UPDATE
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      onClick={this.onHandleAddCountryMaintenance}
                      data-test="button-add"
                      disabled={!this.state.accessGrantWriteCM}
                    >
                      ADD
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-corridorReport"
                      onClick={this.onHandleCorridorReport}
                      disabled={!this.state.accessGrantCM && !this.state.accessCountryMaint}
                    >
                      CORRIDOR REPORT
                    </button>
                    <ExcelFile
                      ref={(ref) => (this.excelrefCorridorReport = ref)}
                      filename="CorridorReport"
                      element={<></>}
                    >
                      <ExcelSheet
                        data={this.state.corridorRows}
                        name="CorridorReport"
                      >
                        {this.getExcelCorridorColumn()}
                      </ExcelSheet>
                    </ExcelFile>
                    <button
                      type="button"
                      className="btn btn-warning mar-right10"
                      data-test="button-countryReport"
                      onClick={this.onHandleCountryReport}
                      disabled={!this.state.accessGrantCM && !this.state.accessCountryMaint}
                    >
                      COUNTRY REPORT
                    </button>
                    <ExcelFile
                      ref={(ref) => (this.excelrefCountryReport = ref)}
                      filename="CountryReport"
                      element={<></>}
                    >
                      <ExcelSheet
                        data={this.state.countryRows}
                        name="CountryReport"
                      >
                        {this.getExcelCountryColumn()}
                      </ExcelSheet>
                    </ExcelFile>
                    <button
                      type="button"
                      className="btn btn-warning"
                      data-test="button-changeReport"
                      onClick={this.onHandleChangeReport}
                      disabled={!this.state.accessGrantCM && !this.state.accessCountryMaint}
                    >
                      CHANGE REPORT
                    </button>
                    <ExcelFile
                      ref={(ref) => (this.excelrefChangeReport = ref)}
                      filename="ChangeReport"
                      element={<></>}
                      data-test="ref-changeReport"
                    >
                      <ExcelSheet
                        data={this.state.changeRows}
                        name="ChangeReport"
                      >
                        {this.getExcelChangeColumn()}
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withOktaAuth(CountryDatabaseMaintenance);
